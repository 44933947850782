import React, { useEffect, useState } from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import Button from "react-bootstrap/Button";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { FaFacebookSquare, FaInstagram, FaLinkedin, FaPinterestSquare, FaTwitterSquare, FaWhatsappSquare } from "react-icons/fa";
import { HashLink } from "react-router-hash-link";
import { ToastContainer, toast } from "react-toastify";
import { Form, FloatingLabel, Row, Col } from "react-bootstrap";
import Loader from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.css";

const FooterComponent = ({history}) => {
    const [mailValid, setMailValid] = useState(true);
    const [email, setEmail] = useState("");
    const [loader, setLoader] = useState(false);
    const [valid,setValid]=useState()
    useEffect(() => {
        document.getElementById("year").innerHTML = new Date().getFullYear();
    }, [])

    const sendClientMail = (data) => {
        const response = fetch(`${process.env.REACT_APP_SUBSCRIBE_EMAIL}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then(handleError)
            .then(async (res) => {
                const resData = res;
                if (resData.status == "success") {
                    toast.configure({
                        pauseOnFocusLoss: false,
                    });
                    toast.success("Thank you for subscribing to our Newsletter.", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    // this.setState({
                    //     email: "",
                    //     mailValid: true,
                    //     loader: false,
                    // });
                    setEmail("");
                    setMailValid(true);
                    setLoader(false)
                } else if (resData.status === "fail") {
                    toast.error("Sorry, we failed to subscribe you. Please try again.", {
                        position: toast.POSITION.TOP_CENTER,
                    });

                    // )
                    // this.setState({
                    //     mailValid: true,
                    //     loader: false,
                    // });
                    setMailValid(true);
                    setLoader(false)
                }
            });
    }
    const handleError = (response) => {
        if (!response.ok) {
            console.log(response);
            const res = fetch(`${process.env.REACT_APP_ERROR_LOGGER}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    error: `${response.statusText}`,
                    errorCode: `${response.status}`,
                    errorPage: `${document.URL}`,
                    apiHit: `${response.url}`,
                }),
            });
        }
        return response.json();
    };
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        var valid = true;

        //Email
        if (
            /^[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(event.target[0].value)
        ) {
            // this.setState({ mailValid: true });
            setMailValid(true)
        } else {
            // this.setState({ mailValid: false });
            // valid = false;
            setMailValid(false);
            setValid(false)
        }

        event.preventDefault();
        event.stopPropagation();

        if (valid) {
            let data = {
                email: email,
                name: "There",
                mobile: "",
                messageSelf: "",
                subjectSelf: "New newsletter subscription from " + email,
                subjectClient: "Thanks for Joining Our Flock!",
                messageClient:
                    "Thanks for subscribing to our newsletters. We are glad to have you with our community. Stay tuned for good things to come!",
            };

            // this.setState(
            //     {
            //         loader: true,
            //     },
            //     () => {
            //         this.sendClientMail(data);

            //     }
            // );
            setLoader(true);
            sendClientMail(data);
        }
    };

    const onImageClick = () => {
        history.push({
            pathname: "/",
            state: { id: "home" },
        });
    };



    return (
        <>
            <div id="nav-collapse" className="col-md-12 col-xs-12 col-sm-12 col-lg-12 footerBg">

                <div className="container" style={{ zIndex: "0" }}>
                    <div className="row">
                        <div className="col-md-4 col-xs-12 col-sm-12 footer_div">
                            <img
                                src={"/images/logo.png"}
                                alt="logo"
                                onClick={onImageClick}
                                style={{ width: "45%" }}
                            />
                            <p className="footdesc">
                                The Best Digital Transformation Under One Roof Is the Sound of the
                                Future.
                            </p>
                            <a
                                href={`tel:${process.env.REACT_APP_MobileNO}`}
                                style={{ textDecoration: "none" }}
                            >
                                <p className="footdesc">
                                    <PhoneIcon
                                        style={{
                                            color: "white",
                                            fontSize: "18px",
                                            marginRight: "3%",
                                        }}
                                    />
                                    {""}
                                    {process.env.REACT_APP_MobileNO}
                                </p>
                            </a>
                            <a
                                href={`mailto:${process.env.REACT_APP_EMAIL}`}
                                style={{ textDecoration: "none" }}
                            >
                                <p className="footdesc" style={{ width: "85%" }}>
                                    <MailOutlineIcon
                                        style={{
                                            color: "white",
                                            fontSize: "18px",
                                            marginRight: "3%",
                                        }}
                                    />
                                    {"  "}
                                    {process.env.REACT_APP_EMAIL}
                                </p>
                            </a>
                        </div>
                        <div className="col-md-2 col-xs-12 col-sm-12 footer_div">
                            <ul className="footerNav">
                                <HashLink to="/">
                                    <li>Home</li>
                                </HashLink>
                                <HashLink to="/about">
                                    <li>About Us</li>
                                </HashLink>
                                <HashLink to="/it-services">
                                    <li>IT Service</li>
                                </HashLink>
                                <HashLink to="/digital-transformation-services">
                                    <li>Digital Services</li>
                                </HashLink>
                                <HashLink to="/contact">
                                    <li>Contact Us</li>
                                </HashLink>
                            </ul>
                        </div>
                        <div className="col-md-2 col-xs-12 col-sm-12 footer_div">
                            <ul className="footerNav">
                                <HashLink to="/terms">
                                    <li>Terms of use</li>
                                </HashLink>
                                <HashLink to="/policy">
                                    <li>Privacy Policy</li>
                                </HashLink>
                            </ul>
                        </div>
                        <div className="col-md-4 col-xs-12 col-sm-12 footer_div">
                            <p className="joinNews">Join a Newsletter</p>
                            <p className="inputLabel pb-1">Your Email</p>
                            <Form
                                noValidate
                                validated={mailValid}
                                onSubmit={(e) => {
                                    handleSubmit(e);
                                }}
                                className="footer_form"
                            >
                                <Form.Group
                                    controlId="Email"
                                    style={{ width: "55%", float: "left" }}
                                >
                                    <Form.Control
                                        type="email"
                                        placeholder="name@example.com"
                                        value={email}
                                        style={{
                                            color: "#000000",
                                            height: "50px",
                                            borderRadius: "0",
                                        }}
                                        onChange={(e) => {
                                            // this.setState({
                                            //     email: e.target.value,
                                            // });
                                            setEmail(e.target.value)
                                        }}
                                    />
                                </Form.Group>
                                {loader ? (
                                    <Loader
                                        type="Puff"
                                        color="#00BFFF"
                                        height={30}
                                        width={30}
                                        className="loaderSpin"
                                    />
                                ) : (
                                    <Button
                                        variant="primary"
                                        style={{
                                            borderRadius: "0 !important",
                                            padding: "3% 6%",
                                            fontSize: "14px",
                                            height: "50px",
                                            borderRadius: "0",
                                        }}
                                        type="submit"
                                    >
                                        Subscribe
                                    </Button>
                                )}
                                <ToastContainer />
                            </Form>
                            <p style={{ marginTop: "2%", textAlign: "left", width: "85%" }}>
                                {!mailValid ? (
                                    <div
                                        style={{
                                            color: "#dc3545",
                                            fontSize: ".875em",
                                            marginTop: "",
                                            marginBottom: "2%"
                                        }}
                                    >
                                        Please provide a valid Email.
                                    </div>
                                ) : (
                                    ""
                                )}
                                <FaFacebookSquare
                                    fontSize="22px"
                                    style={{ cursor: "pointer" }}
                                    onClick={(event) =>
                                        window.open(`${process.env.REACT_APP_FACEBOOK}`, "_blank")
                                    }
                                />
                                &nbsp;&nbsp;{" "}
                                <FaTwitterSquare
                                    fontSize="22px"
                                    style={{ cursor: "pointer" }}
                                    onClick={(event) =>
                                        window.open(`${process.env.REACT_APP_TWITTER}`, "_blank")
                                    }
                                />
                                &nbsp;&nbsp;{" "}
                                <FaLinkedin
                                    fontSize="22px"
                                    style={{ cursor: "pointer" }}
                                    onClick={(event) =>
                                        window.open(`${process.env.REACT_APP_LINKEDIN}`, "_blank")
                                    }
                                />
                                &nbsp;&nbsp;
                                <FaInstagram
                                    fontSize="22px"
                                    style={{ cursor: "pointer" }}
                                    onClick={(event) =>
                                        window.open(`${process.env.REACT_APP_INSTAGRAM}`, "_blank")
                                    }
                                />
                                &nbsp;&nbsp;
                                <FaPinterestSquare
                                    fontSize="22px"
                                    style={{ cursor: "pointer" }}
                                    onClick={(event) =>
                                        window.open(`${process.env.REACT_APP_PRINTEREST}`, "_blank")
                                    }
                                />
                                &nbsp;&nbsp;
                                <FaWhatsappSquare
                                    fontSize="22px"
                                    style={{ cursor: "pointer" }}
                                    onClick={(event) =>
                                        window.open(`https://wa.me/${process.env.REACT_APP_WhatsappNo}?text=${process.env.REACT_APP_WhatsappChat}`, "_blank")
                                    }
                                />
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="copyRightFooter" style={{ textAlign: "center", fontSize: "15px", paddingTop: "2%", paddingBottom: "2%" }}>
                ©<span id="year"></span> Idea to Infinite. All Right Reserved
            </div>
        </>
    )
}
export default FooterComponent