import { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import Layout from "../../BasicComp/Layout";
import { HashLink } from "react-router-hash-link";
import Button from "react-bootstrap/Button";
import Footer from "../../BasicComp/Footer";
import FooterComponent from "../../BasicComp/FooterComponet";
import CustomModal from "../CustomModal";
import cta4 from "../../../img/cta4.jpeg";
import NavbarComponent from "../../BasicComp/Navbar";
import LayoutComponent from "../../BasicComp/LayoutComponent";


const  HomePageFunctional = ({ history }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectArr, setSelectArr] = useState([]);
    const onContact = () => {
        setShowModal(true)
    }

    var ctaseparator = {
        width: "100%",
        padding: "0",
        backgroundImage: `url(${cta4})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    };
    const hideModal = () => {
        setShowModal(false)
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <MetaTags>
                <title>Idea to Infinite - Home</title>
                <meta
                    name="description"
                    content="A one-stop platform to scale your business in the digital world from local to global. The best digital transformation under one roof."
                />
                <meta
                    property="og:title"
                    content="The Best Digital Transformation Under One Roof - Is the Sound of the Future"
                />
                <meta property="og:image" content="images/home1.png" />
            </MetaTags>

            <div style={{ zIndex: "0" }}>
                {/* <Layout history={history}></Layout> */}
                <LayoutComponent history={history} />
            </div>
            <div style={{ zIndex: "0" }} id="nav-collapse">
                <div
                    className="col-md-12 col-xs-12 col-sm-12 col-lg-12"
                    style={{
                        // backgroundImage: `url(images/homebg2.png)`,
                        background: "url(home-webp/homebg2.webp)",
                        height: "585px",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                    }}
                >

                    <div className="bannerMod">
                        <div className="banner-text">
                            <h1 className="responsive-headline homeResponsiveHeadline">
                                The Best Digital Transformation Under One Roof Is The Sound Of
                                Future
                            </h1>
                            <h2 className="text-white homeResponsiveText">
                                Getting digital is about achieving business expertise and
                                invention at scale. A one-stop platform to scale your business
                                in the digital world from local to global. You name it and we
                                have it all.
                            </h2>
                        </div>
                        <div className="CTAButton text-center mt-4">
                            <HashLink to="/it-services">
                                <Button
                                    variant="primary"
                                    style={{ marginRight: "2%", borderRadius: "15px 0" }}
                                >
                                    Explore Services
                                </Button>
                            </HashLink>
                            <Button
                                variant="outline-light"
                                style={{ borderRadius: "15px 0", marginLeft: "2%" }}
                                onClick={onContact}
                            >
                                Get Free Advice
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="row m-0 home_secondSec home_SecondSecMob">
                    <div
                        className="col-md-6 col-xs-12 col-sm-12 col-lg-6 infiImg"
                        style={{ padding: "8% !important" }}
                    >
                        <img
                            // src={"images/home1.png"}
                            src={"home-webp/home1.webp"}
                            className=""
                            alt="Services For Digital Transformation"
                        ></img>
                    </div>
                    <div className="col-md-6 col-xs-12 col-sm-12 col-lg-6 sectionPadding home_secondSecCont">
                        <h2 className="sectionHeadText1">Who We Are?</h2>
                        <h3 className="sectionHeadText2">
                            Provider Of Infinite Services For Digital Transformation.
                        </h3>
                        <p className="sectionParaText">
                            We help your business meet the ever-evolving digital
                            transformation with our secure and adjustable end-to-end
                            services
                        </p>

                        <p className="sectionParaText">
                            Being the Industry leading IT company, we take your business
                            from local to global and from on the ground to the digital world
                            with all the financial compatibility.
                        </p>
                        <p className="sectionParaText">
                            Providing best in market IT solutions from a basic website to
                            cloud migration.
                        </p>
                        <p className="sectionParaText">
                            With the year of experience and profound expertise, we rapidly
                            create efficiency and scale customer business operations.
                        </p>

                        <HashLink to="/funabout">
                            Learn More{" "}
                            <img
                                src={"Icons/Next.svg"}
                                className="iconNext"
                                alt="next"
                            ></img>
                        </HashLink>
                    </div>
                </div>
                <div
                    className="row m-0 mt-5 home_fourthsec third_Sec home_thirdsec1"
                    style={{ backgroundColor: "rgba(12, 77, 208, 0.18)" }}
                >
                    <div
                        className="col-md-5 col-sm-12 order-md-last marginImg lastImg homeThirdMob"
                        style={{ paddingTop: "0px !important", marginTop: "-5%" }}
                    >
                        {/* <img src={"images/sec2.png"} className="" alt="next"></img> */}
                        <img src={"home-webp/sec2.webp"} className="" alt="next"></img>

                    </div>

                    <div className="col-md-5 offset-md-1 col-sm-12 order-md-first sectionPadding tab_para lastCont ">
                        <h3 className="sectionHeadText2 ">Simplifying Lives</h3>
                        <p className="sectionParaText w-100 mob_text">
                            In the era of digital transformation, everyone wants to go
                            digital. But handling the transformation is not easy. Time is a
                            crucial factor and one cannot lose focus on existing customers
                            while trying to transform digitally. Focus on your business and
                            we will take care of your digital transformation. We bring all
                            the services required to transform digitally under the same
                            roof.
                        </p>
                        <p className="sectionParaText w-100 mob_text">
                            While most people try to go digital, they face the complexity of
                            going through a time consuming process. We help you save time
                            and effort by simplifying the process and getting all the
                            services under the same roof.
                        </p>
                    </div>
                </div>
                <div className="row m-0 home_fourthsec">
                    <div
                        className="col-md-5 offset-md-1 col-xs-12 col-sm-12 sec2Img "
                        style={{ padding: "5%" }}
                    >
                        <img
                            // src={"images/home3.png"}
                            src={"home-webp/home3.webp"}
                            className="sectwoImg"
                            alt="business through technology"
                        ></img>
                    </div>
                    <div className="col-md-5 col-xs-12 col-sm-12 sectionPadding home_fourthsecCont">
                        <h2 className="sectionHeadText1">What Do We Do?</h2>
                        <h3 className="sectionHeadText2">
                            Think IT Service - <br />
                            Transform Your Business Through Technology
                        </h3>
                        <p className="sectionParaText">
                            We help you achieve your technological goals by agile process
                            and innovative thinking. Evolve your business by transforming it
                            through a wide range of services we offer.
                        </p>
                        <p className="sectionParaText">
                            Our IT services are a complete package of different service
                            offerings. We offer IT solutioning to meet your enterprise IT
                            needs. API services to share your data for expanding your reach
                            and connecting to different domains. CMS to customize and manage
                            your content with your hold in collaboration. Cloud services to
                            scale your business while making it future proof.
                        </p>
                        <p className="sectionParaText">
                            Get the best of all we have and let technology help you
                            transform your business.
                        </p>

                        <HashLink to="/funitservice">
                            Learn More{" "}
                            <img
                                src={"Icons/Next.svg"}
                                className="iconNext"
                                alt="next"
                            ></img>
                        </HashLink>
                    </div>
                </div>
                <div
                    className="row homeSectionMargin  home_thirdsec1"
                    style={{ backgroundColor: "rgba(11, 80, 118, 0.21)" }}
                >
                    <div
                        className="col-md-5 col-xs-12 col-sm-12 order-md-last  lastImg "
                        style={{ padding: "0 4% 2% 4%", marginTop: "-5%" }}
                    >
                        <img
                            // src={"images/home4.png"}
                            src={"home-webp/home4.webp"}
                            className="sec3img"
                            alt="transform digitally"
                        ></img>
                    </div>

                    <div
                        className="col-md-5 offset-md-1 col-xs-12 col-sm-12 sectionPadding order-md-first tab_para lastCont"
                        style={{ paddingBottom: "12% !important" }}
                    >
                        <h3 className="sectionHeadText2">
                            Are You Ready To Transform Digitally?
                        </h3>
                        <p className="sectionParaText w-100 mob_text">
                            Digital transformation is a complete process involving multiple
                            services. Idea to Infinite comes with an experienced team of
                            individuals to provide all the services under the same roof.
                        </p>
                        <p className="sectionParaText w-100 mob_text">
                            Starting from Company Incorporation, Accounting, Legal Advisory,
                            Branding, IT Solutioning, Marketing & Business Consultation are
                            all the services on a single platform to save you time adapting
                            the new normal to transform your business digitally.
                        </p>
                        <HashLink to="/fundigital">
                            Learn More{" "}
                            <img
                                src={"Icons/Next.svg"}
                                className="iconNext"
                                alt="next"
                            ></img>
                        </HashLink>
                    </div>
                </div>

                <div className="row mt-3 m-0">
                    <div className="col-md-12 col-xs-12 col-sm-12 goDigitalPadding">
                        <h3
                            className="sectionHeadText2 text-center"
                            style={{ fontSize: "28px !important" }}
                        >
                            Go Digital With 5 End-To-End Services
                        </h3>
                        <p className="sectionParaText w-100 text-center pt-2">
                            We trust in bringing solutions that will have a quantifiable
                            positive impression on our clients’ business and bottom line.
                        </p>
                        <HashLink
                            to="/digital-transformation-services"
                            className="dtlink"
                        >
                            <p className="text-center">
                                Digital Transformation
                                <img
                                    src={"Icons/Next.svg"}
                                    className="iconNext"
                                    alt="next"
                                ></img>
                            </p>
                        </HashLink>
                    </div>
                </div>
                <div className="row m-0 mb-5 home_col">
                    <div
                        className="col-md-2 offset-md-1 col-sm-6 p-2 text-center home_col1 "
                        style={{ position: "relative" }}
                    >
                        <Link
                            to="/fundigital/accounting"
                        >
                            <img
                                // src={"images/d1.png"}
                                src={"home-webp/d1.webp"}
                                className="digital5"
                                alt="Accounting"
                            ></img>
                            <p className="digital5text">Accounting</p>
                        </Link>
                    </div>
                    <div
                        className="col-md-2 col-sm-6 p-2 text-center "
                        style={{ position: "relative" }}
                    >
                        <Link
                            to="/fundigital/legal"
                        >
                            <img
                                // src={"images/d2.png"}
                                src={"home-webp/d2.webp"}
                                className="digital5"
                                alt="Legal"
                            ></img>
                            <p className="digital5text">Legal Compliances</p>
                        </Link>
                    </div>

                    <div
                        className="col-md-2 col-sm-6 p-2 text-center"
                        style={{ position: "relative" }}
                    >
                        <Link
                            to="/fundigital/branding"
                        >
                            <img
                                // src={"images/d3.png"}
                                src={"home-webp/d3.webp"}
                                className="digital5"
                                alt="Branding"
                            ></img>
                            <p className="digital5text">Branding</p>
                        </Link>
                    </div>

                    <div
                        className="col-md-2 col-sm-6 p-2 text-center"
                        style={{ position: "relative" }}
                    >
                        <Link
                            to="/fundigital/it-solutioning"
                        >
                            <img
                                // src={"images/d4.png"}
                                src={"home-webp/d4.webp"}
                                className="digital5"
                                alt="IT Solutioning"
                            ></img>
                            <p className="digital5text">IT Solutioning</p>
                        </Link>
                    </div>

                    <div
                        className="col-md-2 col-sm-6 p-2 text-center"
                        style={{ position: "relative" }}
                    >
                        <Link
                            to="/fundigital/marketing"
                        >
                            <img
                                // src={"images/d5.png"}
                                src={"home-webp/d5.webp"}
                                className="digital5"
                                alt="Marketing"
                            ></img>
                            <p className="digital5text">Marketing</p>
                        </Link>
                    </div>
                </div>

                <div
                    className="row home_sixthsec"
                    style={{
                        backgroundColor: "rgba(12, 77, 208, 0.18)",
                        marginTop: "15%",
                        marginLeft: "0",
                        marginRight: "0",
                    }}
                >
                    {/* <div
              className="col-md-3 col-xs-12 col-sm-12 mb-4 p-0"
              style={{ paddingTop: "0px !important", marginTop: "-5%" }}
            >
              <img
                src={"images/home5.png"}
                className=""
                alt="Making Businesses Successful"
              ></img>
            </div> */}
                    <div
                        className="mb-4 p-0 cornerSec"
                        style={{ paddingTop: "0px !important", marginTop: "-5%" }}
                    >
                        <img
                            // src={"images/home5.png"}
                            src={"home-webp/home5.webp"}
                            className=""
                            alt="Making Businesses Successful"
                        ></img>
                    </div>

                    <div
                        className="col-md-7 offset-md-1 col-xs-12 col-sm-12 sectionPadding sixthsec_cont"
                        style={{
                            paddingTop: "0px !important",
                            marginTop: "-9% ",
                        }}
                    >
                        <h2 className="sectionHeadText1">Why Idea To Infinite?</h2>
                        <h3 className="sectionHeadText2">
                            Idea To Infinite. Making Businesses <br />
                            Successful In A Changing World.
                        </h3>
                        <p className="sectionParaText w-100 paraEight">
                            Idea to Infinite is on a mission to help business transform
                            digitally with the help of innovative thinking and efficient
                            execution which leads to infinite growth. We help businesses get
                            flexible with the new era of technology while using agile
                            process for design and development.
                        </p>

                        <div className="row mt-5 sixthsec_innercont">
                            <div className="col-md-4 mt-2 text-center">
                                <span className="">
                                    <img
                                        src={"Icons/Home/Why CHoose us/ic1.svg"}
                                        className="homeIcon"
                                        style={{ height: "60px", width: "60px" }}
                                        alt="why choose us"
                                    ></img>
                                </span>

                                <h4 className="iconHead">Ideas with Passion</h4>
                                <p className="iconPara">
                                    Our Passion, your Business.
                                    <br />
                                    We commit to delivering magnificent cutting-edge IT
                                    solutions that add real value.
                                </p>
                            </div>
                            <div className="col-md-4 mt-2 text-center">
                                <span className="">
                                    <img
                                        src={"Icons/Home/Why CHoose us/ic2.svg"}
                                        className="homeIcon"
                                        style={{ height: "60px", width: "60px" }}
                                        alt="different services you need
                      for your business"
                                    ></img>
                                    <h4 className="iconHead">Single Point of Contact</h4>
                                    <p className="iconPara">
                                        Stop here, to get all the different services you need for
                                        your business.
                                    </p>
                                </span>
                            </div>
                            <div className="col-md-4 mt-2 text-center">
                                <span className="">
                                    <img
                                        src={"Icons/Home/Why CHoose us/ic3.svg"}
                                        className="homeIcon"
                                        style={{ height: "60px", width: "60px" }}
                                        alt="within budget"
                                    ></img>
                                    <h4 className="iconHead">On-Time and Within Budget</h4>
                                    <p className="iconPara">
                                        We promise reducing go to market time results in saving
                                        time, money and effort.
                                    </p>
                                </span>
                            </div>
                            <div className="col-md-4 mt-2 text-center">
                                <span className="">
                                    <img
                                        src={"Icons/Home/Why CHoose us/ic4.svg"}
                                        className="homeIcon"
                                        style={{ height: "60px", width: "60px" }}
                                        alt="administrations"
                                    ></img>
                                    <h4 className="iconHead">Under the Same Umbrella</h4>
                                    <p className="iconPara">
                                        We bring the best to the table for every one of the
                                        administrations under a similar rooftop with an
                                        accomplished group.
                                    </p>
                                </span>
                            </div>
                            <div className="col-md-4 mt-2 text-center">
                                <span className="">
                                    <img
                                        src={"Icons/Home/Why CHoose us/ic5.svg"}
                                        className="homeIcon"
                                        style={{ height: "60px", width: "60px" }}
                                        alt="global recognition"
                                    ></img>
                                    <h4 className="iconHead">Global Recognition</h4>
                                    <p className="iconPara">
                                        Reaching global customers is difficult. We help the
                                        business reach its audience and get global recognition.
                                    </p>
                                </span>
                            </div>
                            <div className="col-md-4 mt-2 text-center">
                                <span className="">
                                    <img
                                        src={"Icons/Home/Why CHoose us/ic6.svg"}
                                        className="homeIcon"
                                        style={{ height: "60px", width: "60px" }}
                                        alt="24x7 payment"
                                    ></img>
                                    <h4 className="iconHead">Protected hassle-free payments</h4>
                                    <p className="iconPara">
                                        24×7 Payment’s choices for an issue-free exchange even on
                                        weekends and bank occasions.
                                    </p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="col-md-12 col-xs-12 col-sm-12  text-center processModel"
                    style={{ paddingBottom: "12% !important" }}
                >
                    <h3 className="sectionHeadText1">How Do We Do?</h3>
                    <h3 className="sectionHeadText2">
                        8 Process To Provide Best IT Services
                    </h3>
                    <p className="pt-2 pb-2 p-4 w-100 ">
                        Proven Centers of Brilliance to advance your data-driven
                        abilities. To keep pace with the growing demand that is evolving
                        to solve unmet needs and gaps in accelerated IT Services.
                    </p>
                    <img
                        className="deskView"
                        // src={"images/hd.png"}
                        src={"home-webp/hd.webp"}
                        style={{ height: "auto", width: "100%" }}
                        alt="process to provide best IT services"
                    ></img>
                    <div className="mobileview_div">
                        <img
                            className="mobView"
                            src={"images/hm.png"}
                            style={{ height: "auto", width: "100%" }}
                            alt="process to provide best IT services"
                        ></img>
                    </div>
                </div>

                <div className="row m-0 p-0" style={ctaseparator}>
                    {/* <div> */}
                    <div className="col-md-12 col-sm-12 p-0 text-center">
                        <h2 className="text-white p-4 pl-2 pr-2 m-0">
                            Grow Your Business By Transforming Digitally!
                        </h2>
                    </div>
                </div>

                <div className="row m-0 p-0 showPosts">
                    <div className="col-xs-12 col-sm-12 sectionPadding pt-5 text-center">
                        <h3 className="sectionHeadText2 pt-4">Recent Posts</h3>
                        <p className="sectionParaText w-100 p-5 pb-2 pt-2 text-center">
                            Business and technology news from around the world. Exclusive
                            stories and <br />
                            expert analysis on digital transformation, technology, designing
                            and more.
                        </p>
                    </div>
                </div>

                {/* <div className="container showPosts">
            <div className="row m-0">
              {this.state.posts.slice(1).map((post) => (
                <BlogPreview post={post} />
              ))}
            </div>
          </div> */}
                {/* </div> */}
            </div>
            {/* <Footer history={history} /> */}
            <FooterComponent history={history} />

            {
                showModal ? (
                    <CustomModal
                        showModal={showModal}
                        onClose={hideModal}
                        selectedService={selectArr}
                        title="Free Advice"
                    ></CustomModal >
                ) : (
                    <></>
                )}
        </>
    )
}

export default HomePageFunctional


