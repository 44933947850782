import React, { useEffect, useState } from "react";
import Layout from "../../BasicComp/Layout";
import Button from "react-bootstrap/Button";
import Footer from "../../BasicComp/Footer";
import FooterComponent from "../../BasicComp/FooterComponet";

import { Link } from "react-router-dom";
import CardDetails from "../CardDetails";
import CustomServices from "../CustomServices";
import cta3 from "../../../img/cta3.jpg";
import MetaTags from "react-meta-tags";
import CustomModal from "../CustomModal";
import Breadcrumb from "../../BasicComp/Breadcrumb";
import LayoutComponent from "../../BasicComp/LayoutComponent";


const EndToEndServicePageFunctional = ({ history }) => {
    const [posts, setPosts] = useState([])
    const [isIT, setisIT] = useState(false);
    const [showCards, setShowCards] = useState(true);
    const [service, setService] = useState("");
    const [nameValid, setNameValid] = useState(true)
    const [emailValid, setEmailValid] = useState(true)
    const [showModal, setShowModal] = useState(false);
    const [selectArr, setSelectArr] = useState([]);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const onContact = () => {
        // this.setState({
        //     showModal: true,
        // });
        setShowModal(true)
    }

    const hideModal = () => {
        // this.setState({
        //     showModal: false,
        // });
        setShowModal(false);

    }
    const cardBackClick = () => {
        // this.setState({
        //     isIT: false,
        //     showCards: true,
        //     service: "",
        // });
        setisIT(false);
        setShowCards(true);
        setService('');
    }

    const toggleCards = (item) => {
        if (item == "ACCOUNTING") {
            // this.setState({
            //     isIT: true,
            //     showCards: false,
            //     service: "ACCOUNTING",
            // });
            setisIT(true);
            setShowCards(false);
            setService("ACCOUNTING");
        } else if (item == "IT") {
            // this.setState({
            //     isIT: true,
            //     showCards: false,
            //     service: "IT",
            // });
            setisIT(true);
            setShowCards(false);
            setService("IT");
        } else if (item == "BRANDING") {
            // this.setState({
            //     isIT: true,
            //     showCards: false,
            //     service: "BRANDING",
            // });
            setisIT(true);
            setShowCards(false);
            setService("BRANDING");
        } else if (item == "LEGAL") {
            // this.setState({
            //     isIT: true,
            //     showCards: false,
            //     service: "LEGAL",
            // });
            setisIT(true);
            setShowCards(false);
            setService("LEGAL");
        } else if (item == "MARKETING") {
            // this.setState({
            //     isIT: true,
            //     showCards: false,
            //     service: "MARKETING",
            // });
            setisIT(true);
            setShowCards(false);
            setService("MARKETING");
        } else {
            // this.setState({
            //     isIT: false,
            // });
            setisIT(false)
        }
    };

    const items = [
        { text: "Home", url: "/" },
        { text: "Digital Transformation Services", url: "/fundigital" },
    ];
    var verticalCard1 = {
        width: "100%",
        padding: "0",
        backgroundImage: `url(digitalservice-webp/Accounting-v1.webp)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    };
    var verticalCard2 = {
        width: "100%",
        padding: "0",
        backgroundImage: `url(digitalservice-webp/LegalV1.webp)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    };
    var verticalCard3 = {
        width: "100%",
        padding: "0",
        backgroundImage: `url(digitalservice-webp/E3.webp)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    };
    var verticalCard4 = {
        width: "100%",
        padding: "0",
        backgroundImage: `url(digitalservice-webp/ITSoultion-v1.webp)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    };
    var verticalCard5 = {
        width: "100%",
        padding: "0",
        backgroundImage: `url(digitalservice-webp/E5.webp)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    };
    var ctaseparator = {
        width: "100%",
        padding: "0",
        backgroundImage: `url(${cta3})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    };
    return (
        <>
            <MetaTags>
                <title>
                    Idea to Infinite - End-to-End Digital Transformation Services
                </title>
                <meta
                    name="description"
                    content="The IT Application Services practice at Idea to Infinite is focused on providing a unique service made possible by our digital capabilities. Uncover the potential of digital and build a future ready business."
                />
                <meta
                    property="og:title"
                    content="Uncover the Potential of Digital - Build a Future-Ready Business"
                />
                <meta property="og:image" content="images/it_girl.png" />
            </MetaTags>

            <div style={{ zIndex: "0" }}>
                {/* <Layout history={history}></Layout> */}
                <LayoutComponent history={history} />

            </div>
            <div style={{ zIndex: "0" }} id="nav-collapse">
                <div
                    className="col-md-12 col-xs-12 col-sm-12 col-lg-12"
                    style={{
                        // backgroundImage: `url(images/eteBanner.png)`,
                        backgroundImage: `url(digitalservice-webp/eteBanner.webp)`,
                        height: "585px",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                    }}
                >

                    <div className="bannerMod">
                        <div className="banner-text">
                            <h1 className="responsive-headline">
                                End-To-End Digital Transformation Services
                            </h1>
                            <h2 className="text-white p-0 pt-2">
                                As a top-notch Digital Transformation company providing end to
                                end services, we desire to take your organization to the next
                                level, bridging the gap between you and the success you
                                desire.
                            </h2>
                        </div>
                        <div className="CTAButton text-center mt-4">

                            <Button
                                variant="outline-light"
                                style={{ borderRadius: "15px 0" }}
                                onClick={onContact}
                            >
                                Get Free Advice
                            </Button>
                        </div>
                    </div>
                </div>
                <Breadcrumb items={items} />
                <div className="row m-0">
                    <div className="col-md-5 col-xs-12 col-sm-12 p-5 pb-3 pt-2 order-md-last digital_SecImg">
                        <img
                            // src={"images/it_girl.png"}
                            src={"digitalservice-webp/it_girl.webp"}
                            className=""
                            alt="The IT Application Services practice"
                        ></img>
                    </div>
                    <div className="col-md-5 offset-md-1 col-xs-12 pt-3 pb-3 col-sm-12 sectionPadding order-md-first digital_Sec">
                        <h3 className="sectionHeadText2">
                            Uncover the Potential of Digital! <br />
                            Build a Future Ready Business
                        </h3>
                        <p className="sectionParaText w-100">
                            The journey to Digital Transformation with complete end-to-end
                            services made possible on a single platform. Focus on your
                            customers while getting in touch with a single point of contact
                            to lead you towards global recognition. Making businesses
                            self-reliant and cope up with the ever changing market needs
                            along with the hassle free process of getting the things done
                            with inclusive services all together on a single platform.
                        </p>
                    </div>
                </div>
                <div className="row m-0">
                    <div
                        className="col-xs-12 col-sm-12 text-center"
                        id="endtoend"
                        style={{ padding: "2% 5%" }}
                    >
                        <h3 className="sectionHeadText2">
                            End-To-End Digital Transformation
                        </h3>
                        <p className="sectionParaText w-100 pt-2 text-center">
                            Idea to Infinite is a one stop destination for all the services
                            required for customers to go digital. Beginning from Company
                            Inception, obtaining Legal Rights, Branding, IT solutioning to
                            Marketing, all under the same roof.
                        </p>
                    </div>
                </div>

                <div
                    className={`row m-0 p-0 ${showCards ? "" : "vcardDisplay"
                        }`}
                >
                    <div
                        className="verticalCard col-md col-sm-12"
                        style={verticalCard1}
                        onClick={() => toggleCards("ACCOUNTING")}
                    >
                        <Link to="./fundigital/accounting">
                            <span className="vcardText">
                                Accounting
                                <p className="text-white vcardSubText">
                                    Wide range of services for all your accounting and
                                    compliance needs.
                                </p>
                                <p className="exploreMore">
                                    Explore More
                                    <img
                                        src={"Icons/Back.svg"}
                                        className="iconNext"
                                        alt="next"
                                    ></img>
                                </p>
                            </span>
                            <div className="overlay h-100 w-100 m-0"></div>
                        </Link>
                    </div>
                    <div
                        className="col-md col-sm-12 verticalCard"
                        style={verticalCard2}
                        onClick={() => toggleCards("LEGAL")}
                    >
                        <Link to="./fundigital/legal">
                            <span className="vcardText">
                                Legal Compliances
                                <p className="text-white vcardSubText">
                                    Obtain legal rights while complying with complicated
                                    regulations, policies and procedures governing the business
                                    operations.
                                </p>
                                <p className="exploreMore">
                                    Explore More
                                    <img
                                        src={"Icons/Back.svg"}
                                        className="iconNext"
                                        alt="next"
                                    ></img>
                                </p>
                            </span>
                            <div className="overlay h-100 w-100 m-0"></div>
                        </Link>
                    </div>
                    <div
                        className="col-md col-sm-12 verticalCard"
                        style={verticalCard3}
                        onClick={() => toggleCards("BRANDING")}
                    >
                        <Link to="./fundigital/branding">
                            <span className="vcardText">
                                Branding
                                <p className="text-white vcardSubText">
                                    Empower your business while sparking a connection with your
                                    audience.
                                </p>
                                <p className="exploreMore">
                                    Explore More
                                    <img
                                        src={"Icons/Back.svg"}
                                        className="iconNext"
                                        alt="next"
                                    ></img>
                                </p>
                            </span>
                            <div className="overlay h-100 w-100 m-0"></div>
                        </Link>
                    </div>
                    <div
                        className="col-md col-sm-12 verticalCard"
                        style={verticalCard4}
                        onClick={() => toggleCards("IT")}
                    >
                        <Link to="./fundigital/it-solutioning">
                            <span className="vcardText">
                                IT Solutioning
                                <p className="text-white vcardSubText">
                                    Accelerate your growth with cutting edge IT solutions.
                                </p>
                                <p className="exploreMore">
                                    Explore More
                                    <img
                                        src={"Icons/Back.svg"}
                                        className="iconNext"
                                        alt="next"
                                    ></img>
                                </p>
                            </span>
                            <div className="overlay h-100 w-100 m-0"></div>
                        </Link>
                    </div>

                    <div
                        className="col-md col-sm-12 verticalCard"
                        style={verticalCard5}
                        onClick={() => toggleCards("MARKETING")}
                    >
                        <Link to="./fundigital/marketing">
                            <span className="vcardText">
                                Marketing
                                <p className="text-white vcardSubText">
                                    Reach your audience globally with forever associate while
                                    building trust and loyalty.
                                </p>
                                <p className="exploreMore">
                                    Explore More
                                    <img
                                        src={"Icons/Back.svg"}
                                        className="iconNext"
                                        alt="next"
                                    ></img>
                                </p>
                            </span>
                            <div className="overlay h-100 w-100 m-0"></div>
                        </Link>
                    </div>
                </div>

                {service ? (
                    <div
                        className={`row m-0 p-0 ${showCards ? "vcardDisplay" : ""
                            }`}
                    >
                        <CardDetails
                            service={service}
                            backClick={cardBackClick}
                        />
                    </div>
                ) : null}

                <div
                    className={`row m-0 p-0 ${showCards ? "" : "vcardDisplay"
                        }`}
                >
                    <div className="col-xs-12 col-sm-12 col-md-10 offset-md-1 sectionPadding text-center">
                        <p
                            className="sectionParaText w-100 p-2 pb-2"
                            style={{ fontSize: "16px" }}
                        >
                            Digital transformation is a journey consisting of various
                            milestones. When a new or existing business aspires to go
                            digital, they face the challenge of investing a significant
                            amount of time approaching different service providers and a
                            lack of technical expertise may impede their productivity
                            towards existing clients. Idea to Infinite being the single
                            point of contact simplifies the process of going digital with
                            our end-to-end services.
                        </p>
                        <p
                            className="sectionParaText w-100 p-2 pt-0"
                            style={{ fontSize: "16px" }}
                        >
                            Starting from Business Inception, Accounting and Legal
                            Compliances, Branding, IT solutioning to Marketing, Idea to
                            Infinite brings all the services under the same roof with a
                            single point of contact makes it easier for the businesses to
                            rapidly reduce the effort and go to market time. We create
                            tailored solutions for all your business needs. Explore all our
                            services today and start your journey for transforming
                            digitally.
                        </p>
                    </div>
                </div>

                <div className="row m-0 p-0">
                    <div
                        className="col-xs-12 col-sm-12 p-0 text-center"
                        style={ctaseparator}
                    >
                        <h2 className="text-white p-4 m-0">
                            Have Custom Requirements? <br className="br" /> Contact Us Now !
                        </h2>
                    </div>

                    <div
                        className="col-xs-12 col-sm-12 sectionPadding pt-5 pb-3 text-center"
                        style={{ padding: "1% 2% !important" }}
                    >
                        <h3 className="sectionHeadText2">Our Custom Services</h3>
                        <p className="sectionParaText w-100 p-2 pt-2 text-center">
                            Prerequisites such as compliance and approvals must be carefully
                            maintained to enable a smooth flow. A reliable partner that
                            understands the complexities of your supply chain, as well as
                            local restrictions, can make the process go more smoothly.
                        </p>
                    </div>
                </div>

                <div className="container">
                    <div className="row m-0 mb-5 p-0">
                        <CustomServices />
                    </div>
                </div>

                <div className="row m-0 p-0" style={ctaseparator}>
                    <div className="col-md-12 col-sm-12 p-0 text-center">
                        <h2 className="text-white p-4 pl-2 pr-2 m-0">
                            Want To Reach Your Customers Globally?
                            <br className="br" /> Go Digital.
                        </h2>
                    </div>
                </div>

                <div className="row m-0 p-0 showPosts">
                    <div className="col-xs-12 col-sm-12 sectionPadding pt-5 text-center">
                        <h3 className="sectionHeadText2 pt-4">Recent Posts</h3>
                        <p className="sectionParaText w-100 p-5 pb-2 pt-2 text-center">
                            Business and technology news from around the world. Exclusive
                            stories and <br />
                            expert analysis on digital transformation, technology, AI,
                            designing and more.
                        </p>
                    </div>
                </div>

            </div>

            {/* <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12 footerBg"> */}
            {/* <Footer history={history} /> */}
            <FooterComponent history={history} />

            {/* </div> */}

            {showModal ? (
                <CustomModal
                    showModal={showModal}
                    onClose={hideModal}
                    selectedService={selectArr}
                    title="Free Advice"
                ></CustomModal>
            ) : (
                <></>
            )}
        </>
    )
}
export default EndToEndServicePageFunctional;