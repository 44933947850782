import React, { Component } from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import Button from "react-bootstrap/Button";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { FaFacebookSquare, FaInstagram, FaLinkedin,FaPinterestSquare,FaTwitterSquare,FaWhatsappSquare} from "react-icons/fa";
import { HashLink } from "react-router-hash-link";
import { ToastContainer, toast } from "react-toastify";
import { Form, FloatingLabel, Row, Col } from "react-bootstrap";
import Loader from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PinterestIcon from "@mui/icons-material/Pinterest";
import { send } from "emailjs-com";
import InstagramIcon from "@mui/icons-material/Instagram";

import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";

import { emailWelcome, emailSelf, emailSubscribe } from "../Pages/Email";

import { textAlign } from "@mui/system";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mailValid: true,
      email: "",
      // toSend: {
      //   from_name: "",
      //   from_email: "",
      //   from_mobile: "",
      //   from_message: "",
      //   subject: "",
      // },
      loader: false,
    };
  }

  // sendSelfMail(data) {
  //   console.log("data test - ", data);
  //   const response = fetch("https://iemail.ideatoinfinite.com/selfmail", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   })
  //     .then((res) => res.json())
  //     .then(async (res) => {
  //       const resData = res;
  //     });
  // }

  sendClientMail(data) {
    const response = fetch(`${process.env.REACT_APP_SUBSCRIBE_EMAIL}`, {
      // const response = fetch(`http://localhost:3001/subscribes`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(this.handleError)
      .then(async (res) => {
        const resData = res;
        console.log(resData);
        if (resData.status == "success") {
          toast.configure({
            pauseOnFocusLoss: false,
          });
          toast.success("Thank you for subscribing to our Newsletter.", {
            position: toast.POSITION.TOP_CENTER,
          });
          this.setState({
            email: "",
            mailValid: true,
            loader: false,
          });
        } else if (resData.status === "fail") {
          // setInterval(
          toast.error("Sorry, we failed to subscribe you. Please try again.", {
            position: toast.POSITION.TOP_CENTER,
          });

          // )
          this.setState({
            mailValid: true,
            loader: false,
          });
        }
      });
    // this.sendSelfMail(data);
  }
  handleError = (response) => {
    if (!response.ok) {
      console.log(response);
      // console.log(JSON.stringify(response.toString()))

      const res = fetch(`${process.env.REACT_APP_ERROR_LOGGER}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          error: `${response.statusText}`,
          errorCode: `${response.status}`,
          errorPage: `${document.URL}`,
          apiHit: `${response.url}`,
        }),
      });
      //  throw Error(response.statusText);
    }
    return response.json();
  };
  handleSubmit = (event) => {
    const form = event.currentTarget;
    var valid = true;

    //Email
    if (
      /^[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(event.target[0].value)
    ) {
      this.setState({ mailValid: true });
    } else {
      this.setState({ mailValid: false });
      valid = false;
    }

    event.preventDefault();
    event.stopPropagation();

    if (valid) {
      let data = {
        email: this.state.email,
        name: "There",
        mobile: "",
        messageSelf: "",
        subjectSelf: "New newsletter subscription from " + this.state.email,
        subjectClient: "Thanks for Joining Our Flock!",
        messageClient:
          "Thanks for subscribing to our newsletters. We are glad to have you with our community. Stay tuned for good things to come!",
      };

      // this.setState({
      //   loader: true,
      // });
      // setTimeout(() => {
      //   emailSubscribe(data);
      // }, 1000);
      // setTimeout(() => {
      //   emailSelf(data);
      // }, 4000);

      // setTimeout(() => {
      //   this.setState({
      //     mailValid: true,
      //     email: "",
      //     toSend: {
      //       from_name: "",
      //       from_email: "",
      //       from_mobile: "",
      //       from_message: "",
      //       subject: "",
      //     },
      //     loader: false,
      //   });
      //   toast.success("Thank you for subscribing to our newsletters.", {
      //     position: toast.POSITION.TOP_CENTER,
      //   });
      // }, 6000);

      this.setState(
        {
          loader: true,
        },
        () => {
          // setTimeout(() => {
          this.sendClientMail(data);
          // }, 1500);
          // this.sendSelfMail(data);
        }
      );
    }
  };

  onImageClick = () => {
    this.props.history.push({
      pathname: "/",
      state: { id: "home" },
    });
  };

  onLinkClick = (e) => {
    e.target.id == "home"
      ? this.props.history.push({
          pathname: "/",
          state: { id: "home" },
        })
      : e.target.id == "wedo"
      ? this.props.history.push({
          pathname: "/wedo",
          state: { id: "wedo" },
        })
      : e.target.id == "blog"
      ? this.props.history.push({
          pathname: "/blog",
          state: { id: "blog" },
        })
      : e.target.id == "contact"
      ? this.props.history.push({
          pathname: "/contact",
          state: { id: "contact" },
        })
      : this.props.history.push("/");
  };

  componentDidMount(){
    document.getElementById("year").innerHTML = new Date().getFullYear();

  }
  render() {
    return (
      <>
        <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12 footerBg">

      <div className="container" style={{ zIndex: "0" }}>
        <div className="row">
          <div className="col-md-4 col-xs-12 col-sm-12 footer_div">
            <img
              src={"/images/logo.png"}
              alt="logo"
              onClick={this.onImageClick}
              style={{ width: "45%" }}
            />
            <p className="footdesc">
              The Best Digital Transformation Under One Roof Is the Sound of the
              Future.
            </p>
            <a
              href={`tel:${process.env.REACT_APP_MobileNO}`}
              style={{ textDecoration: "none" }}
            >
              <p className="footdesc">
                <PhoneIcon
                  style={{
                    color: "white",
                    fontSize: "18px",
                    marginRight: "3%",
                  }}
                />
                {""}
                {process.env.REACT_APP_MobileNO}
              </p>
            </a>
            <a
              href={`mailto:${process.env.REACT_APP_EMAIL}`}
              style={{ textDecoration: "none" }}
            >
              <p className="footdesc" style={{width:"85%"}}>
                <MailOutlineIcon
                  style={{
                    color: "white",
                    fontSize: "18px",
                    marginRight: "3%",
                  }}
                />
                {"  "}
                {process.env.REACT_APP_EMAIL}
              </p>
            </a>
          </div>
          <div className="col-md-2 col-xs-12 col-sm-12 footer_div">
            <ul className="footerNav">
              <HashLink to="/">
                <li>Home</li>
              </HashLink>
              <HashLink to="/about">
                <li>About Us</li>
              </HashLink>
              <HashLink to="/it-services">
                <li>IT Service</li>
              </HashLink>
              <HashLink to="/digital-transformation-services">
                <li>Digital Services</li>
              </HashLink>
              <HashLink to="/contact">
                <li>Contact Us</li>
              </HashLink>
            </ul>
          </div>
          <div className="col-md-2 col-xs-12 col-sm-12 footer_div">
            <ul className="footerNav">
              <HashLink to="/terms">
                <li>Terms of use</li>
              </HashLink>
              <HashLink to="/policy">
                <li>Privacy Policy</li>
              </HashLink>
            </ul>
          </div>
          <div className="col-md-4 col-xs-12 col-sm-12 footer_div">
            <p className="joinNews">Join a Newsletter</p>
            <p className="inputLabel pb-1">Your Email</p>
            <Form
              noValidate
              validated={this.state.mailValid}
              onSubmit={(e) => {
                this.handleSubmit(e);
              }}
              className="footer_form"
            >
              <Form.Group
                controlId="Email"
                style={{ width: "55%", float: "left" }}
              >
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  value={this.state.email}
                  style={{
                    color: "#000000",
                    height: "50px",
                    borderRadius: "0",
                  }}
                  onChange={(e) => {
                    this.setState({
                      email: e.target.value,
                    });
                  }}
                />
                {/* {!this.state.mailValid ? (
                  <div
                    style={{
                      color: "#dc3545",
                      fontSize: ".875em",
                      marginTop: "0.25rem",
                    }}
                  >
                    Please provide a valid Email.
                  </div>
                ) : (
                  ""
                )} */}
              </Form.Group>
              {this.state.loader ? (
                <Loader
                  type="Puff"
                  color="#00BFFF"
                  height={30}
                  width={30}
                  className="loaderSpin"
                />
              ) : (
                <Button
                  variant="primary"
                  style={{
                    borderRadius: "0 !important",
                    padding: "3% 6%",
                    fontSize: "14px",
                    height: "50px",
                    borderRadius: "0",
                  }}
                  type="submit"
                >
                  Subscribe
                </Button>
              )}
              <ToastContainer />
            </Form>
            <p style={{ marginTop: "2%", textAlign: "left", width: "85%" }}>
            {!this.state.mailValid ? (
                  <div
                    style={{
                      color: "#dc3545",
                      fontSize: ".875em",
                      marginTop: "",
                      marginBottom:"2%"
                    }}
                  >
                    Please provide a valid Email.
                  </div>
                ) : (
                  ""
                )}
              <FaFacebookSquare
                fontSize="22px"
                style={{cursor:"pointer"}}
                onClick={(event) =>
                  window.open(`${process.env.REACT_APP_FACEBOOK}`, "_blank")
                }
              />
              &nbsp;&nbsp;{" "}
              <FaTwitterSquare
                 fontSize="22px"
                style={{cursor:"pointer"}}
                onClick={(event) =>
                  window.open(`${process.env.REACT_APP_TWITTER}`, "_blank")
                }
              />
              &nbsp;&nbsp;{" "}
              <FaLinkedin
                fontSize="22px"
                style={{cursor:"pointer"}}
                onClick={(event) =>
                  window.open(`${process.env.REACT_APP_LINKEDIN}`, "_blank")
                }
              />
              &nbsp;&nbsp;
              <FaInstagram
                fontSize="22px"
                style={{cursor:"pointer"}}
                onClick={(event) =>
                  window.open(`${process.env.REACT_APP_INSTAGRAM}`, "_blank")
                }
              />
              &nbsp;&nbsp;
              <FaPinterestSquare
              fontSize="22px"
              style={{cursor:"pointer"}}
               onClick={(event) =>
                window.open(`${process.env.REACT_APP_PRINTEREST}`, "_blank")
              }
              />
              &nbsp;&nbsp;
              <FaWhatsappSquare
              fontSize="22px"
              style={{cursor:"pointer"}}
               onClick={(event) =>
                window.open(`https://wa.me/${process.env.REACT_APP_WhatsappNo}?text=${process.env.REACT_APP_WhatsappChat}`, "_blank")
              }
              />
            </p>
          </div>
        </div>
        
      </div>
      </div>
      <div className="copyRightFooter" style={{textAlign:"center",fontSize:"15px",paddingTop:"2%",paddingBottom:"2%"}}>
      ©<span id="year"></span> Idea to Infinite. All Right Reserved
      </div>
      </>
    );
  }
}
