import React, { Component } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom"

import { HashLink } from "react-router-hash-link";
import "../../css/underline.css"

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      color_Home: "#b9b9b9",
      color_wedo: "#b9b9b9",
      color_services: "#b9b9b9",
      color_contact: "#b9b9b9",
      colorChange: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.changeNavbarColor);
    if (this.props.history?.location?.state?.id == "home") {
      this.setState({
        color_Home: "#ffffff",
        color_wedo: "#b9b9b9",
        color_services: "#b9b9b9",
        color_contact: "#b9b9b9",
      });
    } else if (this.props?.history?.location?.state?.id == "about") {
      this.setState({
        color_Home: "#b9b9b9",
        color_wedo: "#ffffff",
        color_services: "#b9b9b9",
        color_contact: "#b9b9b9",
      });
    } else if (this.props?.history?.location?.state?.id == "blog") {
      this.setState({
        color_Home: "#b9b9b9",
        color_wedo: "#b9b9b9",
        color_services: "#ffffff",
        color_contact: "#b9b9b9",
      });
    } else if (this.props?.history?.location?.state?.id == "contact") {
      this.setState({
        color_Home: "#b9b9b9",
        color_wedo: "#b9b9b9",
        color_services: "#b9b9b9",
        color_contact: "#ffffff",
      });
    }
    else {
      this.setState({
        color_Home: "#b9b9b9",
        color_wedo: "#b9b9b9",
        color_services: "#b9b9b9",
        color_contact: "#b9b9b9",
      });
    }
  }

  changeNavbarColor = () => {
    if (window.scrollY >= 8) {
      this.setState({
        colorChange: true,
      });
    } else {
      this.setState({
        colorChange: false,
      });
    }
  };

  onImageClick = () => {
    this.props.history.push({
      pathname: "/",
      state: { id: "home" },
    });
  };

  scrollTop = (e) => {
    if (window.location.pathname.substring(1) == e) {
      window.location.reload();
      window.scrollTo(0, 0);
    }
  };

  onLinkClick = (e) => {
    if (window.location.pathname.substring(1) == e.target.id) {
      window.scrollTo(0, 0);
    }
    e.target.id == "home"
      ? this.props.history.push({
          pathname: "/home",
          state: { id: "home" },
        })
      : e.target.id == "about"
      ? this.props.history.push({
          pathname: "/about",
          state: { id: "wedo" },
        })
      : e.target.id == "blog"
      ? this.props.history.push({
          pathname: "/blog",
          state: { id: "blog" },
        })
      : e.target.id == "contact"
      ? this.props.history.push({
          pathname: "/contact",
          state: { id: "contact" },
        })
      : this.props.history.push("/home");
  };
  
  render() {
    const headerNavLink={
      color:"red"
    }
   
    return (
      <div style={{ zIndex: "0" }}>
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="dark"
          fixed="top"
          className="navBg"
          style={{
            // backgroundColor: this.state.colorChange ? "#3E3E3E" : "transparent",
            backgroundColor: this.state.colorChange ? "#fff" : "transparent",
            boxShadow:this.state.colorChange?"0 2px 4px 0 rgba(0,0,0,.2)":"none"
          }}
        >
          <Container>
            <Navbar.Brand>
              <img
                src={"/images/logo.png"}
                alt="logo"
                onClick={this.onImageClick}
                className="navIcon"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={"responsive-navbar-nav"} />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto"></Nav>
              <Nav>
                {/* <Nav.Link
                  className="headerNavLink text-underline"
                  id="home"
                  style={{
                    // color: this.state.color_Home,
                      color:this.state.colorChange?"black":"rgb(185,185,185)",
                  }}
                  onClick={(e) => {
                    this.onLinkClick(e);
                  }}
                >
                  Home
                </Nav.Link> */}
                <NavLink
                  className="headerNavLink text-underline"
                  exact activeClassName="nav-active"
                  id="home"
                  to="/"
                style={{
                  color:this.state.colorChange?"black":this.state.color_wedo,
                  }}
                >
                 Home
                </NavLink>
                {/* <Nav.Link
                  className="headerNavLink text-underline"
                  style={{ */}
                    {/* // color: this.state.color_wedo, */}
                      {/* color:this.state.colorChange?"black":this.state.color_wedo,
                  }}
                  id="about"
                  onClick={(e) => {
                    this.onLinkClick(e);
                  }}
                >
                  About Us

                </Nav.Link> */}
                <NavLink
                  className="headerNavLink text-underline"
                  exact activeClassName="nav-active"
                  id="about"
                  to="/about"
                style={{
                  color:this.state.colorChange?"black":this.state.color_wedo,
                  }}
                >
                 About Us
                </NavLink>
                <NavDropdown
                  className="headerNavLink text-underline test"
                  title={<span
                    className="headerNavLinkService"
                    style={{
                    color:this.state.colorChange?"black":"rgb(185,185,185)",
                  }}>Services</span>}
                  id="navbarScrollingDropdown"
                >
                  <HashLink
                   to="/it-services"
                  >
                    <NavDropdown.Item
                    className="subService"
                    >
                    <NavLink
                    to="/it-services"
                    exact activeClassName="nav-active"
                    style={{
                      color:this.state.colorChange?"black":"black",
                    }}
                    >
                    IT Services
                    </NavLink>
                    </NavDropdown.Item>
                  </HashLink>
                  <HashLink 
                  to="/digital-transformation-services">
                    <NavDropdown.Item
                    className="subService"
                    >
                    <NavLink
                    to="/digital-transformation-services"
                    exact activeClassName="nav-active"
                    style={{
                      color:this.state.colorChange?"black":"black",
                    }}
                    >
                     End-To-End Digital Transformation
                    </NavLink>
                    </NavDropdown.Item>
                  </HashLink>
                </NavDropdown>

                {/* <Nav.Link
                  className="headerNavLink"
                  style={{
                    color: this.state.color_services,
                  }}
                  id="blog"
                  onClick={(e) => {
                    this.onLinkClick(e);
                  }}
                >
                  Blogs
                </Nav.Link> */}

                {/* <Nav.Link
                  className="headerNavLink text-underline"
                  style={{
                    // color: this.state.color_contact,
                      color:this.state.colorChange?"black":this.state.color_contact,
                  }}
                  id="contact"
                  onClick={(e) => {
                    this.onLinkClick(e);
                  }}
                >
                  Contact us
                </Nav.Link> */}
                <NavLink
                  className="headerNavLink text-underline"
                  exact activeClassName="nav-active"
                  id="contact"
                  to="/contact"
                style={{
                  color:this.state.colorChange?"black":this.state.color_wedo,
                  }}
                >
                 Contact Us
                </NavLink>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}
