import React, { Component } from "react";
import Layout from "../BasicComp/Layout";
import Button from "react-bootstrap/Button";
import axios from "axios";
import CardDetails from "./CardDetails";
import BlogPreview from "./BlogPreview";
import Footer from "../BasicComp/Footer";
import { HashLink } from "react-router-hash-link";

import p1 from "../../img/about1.png";
import p2 from "../../img/about2.png";
import p3 from "../../img/about3.png";
import cta from "../../img/cta_separator.png";
import MetaTags from "react-meta-tags";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { send } from "emailjs-com";

export default class NotFound extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    var aboutCard1 = {
      padding: "0",
      backgroundImage: `url(${p1})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "relative",
    };
    var aboutCard2 = {
      width: "100%",
      padding: "0",
      backgroundImage: `url(${p2})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "relative",
    };
    var aboutCard3 = {
      width: "100%",
      padding: "0",
      backgroundImage: `url(${p3})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "relative",
    };
    var ctaseparator = {
      width: "100%",
      padding: "0",
      backgroundImage: `url(${cta})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };

    return (
      <>
        <div style={{ zIndex: "0" }}>
          <Layout history={this.props.history}></Layout>
        </div>
        <div style={{ zIndex: "0" }}>
          <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12">
            <img src={"images/home_bg.png"} className="banner-image"></img>

            <div className="row bannerTextBg m-0">
              <div className="banner-text text-center">
                <img
                  src={"images/Spaceman.png"}
                  style={{ width: "150px" }}
                ></img>
                <h1 className="responsive-headline">
                  Oops, you’re lost in Infinity
                </h1>
                <h2 className="text-white">
                  We can’t find the page that you’re looking for
                </h2>
                <div className="CTAButton text-center mt-4">
                  <HashLink to="/home">
                    <Button
                      variant="primary"
                      style={{ marginRight: "2%", borderRadius: "15px 0" }}
                    >
                      Go Back Home
                    </Button>
                  </HashLink>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12 footerBg"> */}
            <Footer history={this.props.history} />
          {/* </div> */}
        </div>
      </>
    );
  }
}
