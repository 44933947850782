// import React, { Component } from "react";
import "../../css/breadcrumb.css";
import { FaAngleRight } from "react-icons/fa";


// class Breadcrumb extends Component {
//   render() {
//     const currentURL = window.location.pathname;
//     return (
//       <div>
//         <ol className="breadcrumb">
//           {this.props.items.map((item, index) => (
//             <li key={index} className="breadcrumb-item">
//               {item.url === currentURL ? (
//                 <span>{item.text}</span>
//               ) : (
//                 <a href={item.url}>{item.text}</a>
//               )}
//             </li>
//           ))}
//         </ol>
//       </div>
//     );
//   }
// }

// export default Breadcrumb;


/* Breadcrumb.js */
import React, { Component } from "react";

// class Breadcrumb extends Component {
//   render() {
//     const currentURL = window.location.pathname;
//     return (
//       <div className="container">
//         <ol className="breadcrumb">
//           {this.props.items.map((item, index) => (
//             <li key={index} className="breadcrumb-item">
//               {item.url === currentURL ? (
//                 <span>{item.text}</span>
//               ) : (
//                 <a href={item.url}>{item.text}</a>
//               )}
//               {index < this.props.items.length - 1 && (
//                 <span className="breadcrumb-divider"><FaAngleRight/></span>
//               )}
//             </li>
//           ))}
//         </ol>
//       </div>
//     );
//   }
// }
const Breadcrumb = ({ items }) => {
  const currentURL = window.location.pathname;

  return (
    <div className="container">
      <ol className="breadcrumb">
        {items.map((item, index) => (
          <li key={index} className="breadcrumb-item">
            {item.url === currentURL ? (
              <span>{item.text}</span>
            ) : (
              <a href={item.url}>{item.text}</a>
            )}
            {index < items.length - 1 && (
              <span className="breadcrumb-divider"><FaAngleRight /></span>
            )}
          </li>
        ))}
      </ol>
    </div>
  )
}

export default Breadcrumb;
