import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import HomePage from "../Pages/HomePage";
import Header from "./Header";
import Footer from "./Footer";

export default class Layout extends Component {
 
  render() {
    return (
      <>
        <div className="row" id={this.props.id || ""} className={this.props.className || ""}>
          <div
            className="col-md-12 col-xs-12 col-sm-12 col-lg-12">
            <Header history={this.props.history} />
          </div>
          <div
            className="col-md-12 col-xs-12 col-sm-12 col-lg-12">
            {this.props.children}
          </div>
        </div>
      
      </>
    );
  }
}
