import React, { Component } from "react";
import Layout from "../BasicComp/Layout";
import Footer from "../BasicComp/Footer";
import MetaTags from "react-meta-tags";

import p1 from "../../img/about1.png";
import p2 from "../../img/about2.png";
import p3 from "../../img/about3.png";
import cta from "../../img/cta_separator.png";

import "react-toastify/dist/ReactToastify.css";

export default class Terms extends Component {
  componentDidMount() {
    // if (window.location.protocol !== "https:") {
    //   window.location.replace(
    //     `https:${window.location.href.substring(
    //       window.location.protocol.length
    //     )}`
    //   );
    // }
    window.scrollTo(0, 0);
  }

  render() {
    var aboutCard1 = {
      padding: "0",
      backgroundImage: `url(${p1})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "relative",
    };
    var aboutCard2 = {
      width: "100%",
      padding: "0",
      backgroundImage: `url(${p2})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "relative",
    };
    var aboutCard3 = {
      width: "100%",
      padding: "0",
      backgroundImage: `url(${p3})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "relative",
    };
    var ctaseparator = {
      width: "100%",
      padding: "0",
      backgroundImage: `url(${cta})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };

    return (
      <>
        <MetaTags>
          <title>Idea to Infinite - Terms</title>
          <meta
            name="description"
            content="The User agrees and accepts to the terms and conditions
                mentioned in the website."
          />
          <meta
            property="og:title"
            content="Looking for a One-stop Expert IT Service Provider?"
          />
          <meta property="og:image" content="images/contact1.png" />
        </MetaTags>

        <div style={{ zIndex: "0" }}>
          <Layout history={this.props.history}></Layout>
        </div>
        <div style={{ zIndex: "0" }}>
          <div className="row m-0">
            <div
              className="col-md-12 text-center col-sm-12 mb-5 termsHead"
              style={{ paddingTop: "10%" }}
            >
              <h3 className="sectionHeadText2">Terms Of Use</h3>
              <p
                className="sectionParaText w-100"
                style={{ padding: "1% 15%" }}
              >
                The User agrees and accepts to the terms and conditions
                mentioned in the website.
              </p>
              <p
                className="sectionParaText w-100"
                style={{ padding: "1% 15%" }}
              >
                <ul>
                  <li>
                    The term Idea to Infinite means Idea to Infinite Pvt Ltd and
                    the term User shall means that User is browsing the website
                    at www.ideatoinfinite.com.
                  </li>
                  <li>
                    By using the Site, User agrees to follow and be bound by the
                    following terms and conditions and these terms and
                    conditions may be revised or modified at any time by Idea to
                    Infinite Pvt Ltd without any notice to User.
                  </li>
                  <li>
                    The website www.ideatoinfinite.com is under the ownership of
                    Idea to Infinite private limited and all the resources used
                    in the site along with the content and graphics belongs to
                    Idea to Infinite Pvt Ltd which is just for informational
                    purpose and not for sale. These resources cannot be used
                    without the written consent of Idea to Infinite Pvt Ltd and
                    its owners.
                  </li>
                  <li>
                    The Name and Logo of Idea to Infinite are trademark which
                    comes under the ownership of Idea to Infinite Pvt Ltd and
                    its owners and cannot be used anywhere without prior consent
                    in written. Idea to Infinite Pvt Ltd reserves all the rights
                    to take legal action if any of the resources and trademark
                    is used without written consent by Idea to Infinite Pvt Ltd.
                  </li>
                  <li>
                    Any of the unauthorized use of resources and content
                    including the trademark and other applicable laws can result
                    in civil/criminal penalties. Idea to Infinite reserves all
                    the rights to monitor the site for its use.
                  </li>
                  <li>
                    User access and its termination completely falls under the
                    rights of Idea to Infinite Pvt Ltd.
                  </li>
                  <li>
                    Idea to Infinite makes no warranties, limitations, or
                    merchantability w.r.t any information, services, data, and
                    statements available on the website. Idea to Infinite Pvt
                    Ltd, acts as an aggregator platform and shall not be liable
                    to any services delays or customer documents discrepancy. As
                    an aggregator Idea to Infinite just acts a mediator and all
                    the responsibility shall be governed by User to get the work
                    done by providing complete valid information on time. Any
                    delays caused may be depending on customer requirements.
                  </li>
                  <li>
                    Idea to Infinite Pvt Ltd will not be responsible for any
                    damage or failure on user system/data or resource while
                    using the site directly or indirectly. Idea to Infinite is
                    not responsible for any ability or inability to use the
                    site.
                  </li>
                  <li>
                    All the information available on the site is taken from
                    publicly reliable resources available. Idea to Infinite does
                    not claim any warranty, comprehensiveness, reliance,
                    correctness for such information.
                  </li>
                  <li>
                    Idea to Infinite has no warranty that Site meets the
                    complete requirement of User, continuous availability, error
                    free or results obtained by using the site.
                  </li>
                  <li>
                    All the sites and links available along with content can be
                    referenced for publicly available information and does not
                    have guarantee to be static throughout. User shall bear all
                    the risk associated with all the service details and
                    services provided by service providers. Idea to Infinite Pvt
                    Ltd acts as an aggregator platform to help client connect
                    with service provider. Idea to Infinite Pvt Ltd will not be
                    responsible for any loss or damage of any sort of User
                    information while dealing with service provider.
                  </li>
                </ul>
              </p>
            </div>
          </div>

          {/* <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12 footerBg"> */}
            <Footer history={this.props.history} />
          {/* </div> */}
        </div>
      </>
    );
  }
}
