import React, { Component } from "react";
import Layout from "../BasicComp/Layout";
import p1 from "../../img/about1.png";
import p2 from "../../img/about2.png";
import p3 from "../../img/about3.png";
import cta from "../../img/cta_separator.png";
import MetaTags from "react-meta-tags";
import Footer from "../BasicComp/Footer";
import "react-toastify/dist/ReactToastify.css";
import Button from "react-bootstrap/Button";
import axios from "axios";
import CardDetails from "./CardDetails";
import BlogPreview from "./BlogPreview";
import { HashLink } from "react-router-hash-link";


import { ToastContainer, toast } from "react-toastify";

import { send } from "emailjs-com";

export default class Policy extends Component {
  componentDidMount() {
    // if (window.location.protocol !== "https:") {
    //   window.location.replace(
    //     `https:${window.location.href.substring(
    //       window.location.protocol.length
    //     )}`
    //   );
    // }
    window.scrollTo(0, 0);
  }

  render() {
    var aboutCard1 = {
      padding: "0",
      backgroundImage: `url(${p1})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "relative",
    };
    var aboutCard2 = {
      width: "100%",
      padding: "0",
      backgroundImage: `url(${p2})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "relative",
    };
    var aboutCard3 = {
      width: "100%",
      padding: "0",
      backgroundImage: `url(${p3})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "relative",
    };
    var ctaseparator = {
      width: "100%",
      padding: "0",
      backgroundImage: `url(${cta})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };

    return (
      <>
        <MetaTags>
          <title>Idea to Infinite - Privacy Policy</title>
          <meta
            name="description"
            content="At Idea to Infinite, your privacy is very important to us.
                  Idea to Infinite respects your privacy and understands that
                  your data is sensitive."
          />
          <meta
            property="og:title"
            content="Looking for a One-stop Expert IT Service Provider?"
          />
          <meta property="og:image" content="images/contact1.png" />
        </MetaTags>

        <div style={{ zIndex: "0" }}>
          <Layout history={this.props.history}></Layout>
        </div>
        <div style={{ zIndex: "0" }}>
          <div className="row m-0">
            <div
              className="col-md-12 text-center col-sm-12 mb-5 termsHead"
              style={{ paddingTop: "10%" }}
            >
              <h3 className="sectionHeadText2" style={{ paddingBottom: "2%" }}>
                Privacy Policy
              </h3>
              <p
                className="sectionParaText w-100"
                style={{ padding: "1% 15%" }}
              >
                <h4 style={{ color: "black" }}> Introduction </h4>
                <div>
                  At Idea to Infinite, your privacy is very important to us.
                  Idea to Infinite respects your privacy and understands that
                  your data is sensitive. We ensure all protection while
                  collecting the personal and sensitive information. Protecting
                  and safeguarding personal data is basic right of each
                  individual and we respect and ensure all the data collected by
                  us is only used to enhance your experience on website and
                  service delivery.
                </div>
              </p>
              <p
                className="sectionParaText w-100"
                style={{ padding: "1% 15%" }}
              >
                <h4 style={{ color: "black" }}> Copyright and Trademark </h4>
                <div>
                  Every bit of information and material used in this website is
                  owned by Idea to Infinite or it is used after taking
                  permission from their owner. Idea to Infinite holds the right
                  to object if any information available is used without its
                  consent.
                </div>
              </p>
              <p
                className="sectionParaText w-100"
                style={{ padding: "1% 15%" }}
              >
                <h4 style={{ color: "black" }}> Security </h4>
                <div>
                  We built our website with such a web technologies and security
                  measures so that whichever information submitted will be
                  protected. In case you got to know that Idea to Infinite is
                  not following policies you can reach out to
                  {process.env.REACT_APP_EMAIL}.
                </div>
              </p>
              <p
                className="sectionParaText w-100"
                style={{ padding: "1% 15%" }}
              >
                <h4 style={{ color: "black" }}> Functionality </h4>
                <div>
                  We built our website very well but Idea to Infinite does not
                  give surety that every function in web site error free or its
                  server will be free of harmful viruses.
                </div>
              </p>
              <p
                className="sectionParaText w-100"
                style={{ padding: "1% 15%" }}
              >
                <h4 style={{ color: "black" }}> Links </h4>
                <div>
                  We have provided hyperlinks to other websites for valuable
                  information, but those sites are not controlled by Idea to
                  Infinite. So Idea to Infinite will not take guarantee of their
                  website security, content or any other information.
                </div>
              </p>
              <p
                className="sectionParaText w-100"
                style={{ padding: "1% 15%" }}
              >
                <h4 style={{ color: "black" }}> Website traffic logs </h4>
                <div>
                  As Idea to Infinite get data of IP address of visitors which
                  is only used for analysing demographic trend of visitors no
                  other personal information will be extracted from it. This
                  data will gives us information about website performance.
                </div>
              </p>
              <p
                className="sectionParaText w-100"
                style={{ padding: "1% 15%" }}
              >
                <h4 style={{ color: "black" }}> Collection of information </h4>
                <div>
                  As visitor visits the website the web server automatically
                  collects IP address, domain name, how visitor traversed our
                  website other than this, not a single piece of personal
                  information will be recorded by server without intimating the
                  visitor. Visitors are not allowed to use the content on the
                  website without prior consent in written. Idea to Infinite
                  will not be liable for any data collected through any
                  third-party website.
                </div>
              </p>
              <p
                className="sectionParaText w-100"
                style={{ padding: "1% 15%" }}
              >
                <h4 style={{ color: "black" }}> Services </h4>
                <div>
                  Idea to Infinite holds right to discontinue any services or
                  products listed any time based on the availability of the
                  resources and without prior notice. Idea to Infinite acts as a
                  aggregator platform for many services and will not be liable
                  or hold responsible for any delay/discontinuity in service or
                  discrepancy in the information needed/provided to complete the
                  project. All the areas have different rates for different
                  services and rates may differ based on the location and types
                  of services opted along with the nature of the requester
                  business.
                </div>
              </p>
              <p
                className="sectionParaText w-100"
                style={{ padding: "1% 15%" }}
              >
                <h4 style={{ color: "black" }}> Collaborators </h4>
                <div>
                  Any client coming through the website is important to Idea to
                  Infinite and all the information provided to fulfil the
                  service is limited to that assignment and all the information
                  collected shall not be used for any other purpose without
                  written consent from service requester. Collaborator service
                  provider holds all the responsibility for fulfilling the
                  service request along protecting all the information collected
                  by them. Idea to Infinite would always be primary service
                  provider for its customer and collaborator is liable to inform
                  and change is existing service, or any new service requested
                  in future. Collaborators should always communicate with the
                  customers through Idea to Infinite only. No personal
                  information of its customer coming through Idea to Infinite
                  should be used by collaborators without Idea to Infinite’s
                  consent and any such action would result in legal action and
                  penalty for the same.
                </div>
              </p>
              <p
                className="sectionParaText w-100"
                style={{ padding: "1% 15%" }}
              >
                <h4 style={{ color: "black" }}> Complains and Grievances </h4>
                <div>
                  If we received any complaints or grievances regarding private
                  data, personal sensitive information which will be instantly
                  directed towards our data security officer.
                </div>
              </p>
              <p
                className="sectionParaText w-100"
                style={{ padding: "1% 15%" }}
              >
                <h4 style={{ color: "black" }}>
                  {" "}
                  Notification to changes to our policy{" "}
                </h4>
                <div>
                  Any changes in policy will be posted here for the you.
                </div>
              </p>
            </div>
          </div>

          {/* <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12 footerBg"> */}
            <Footer history={this.props.history} />
          {/* </div> */}
        </div>
      </>
    );
  }
}
