import React, { Component } from "react";
import { HashLink } from "react-router-hash-link";
import Moment from "react-moment";
import Button from "react-bootstrap/Button";
import TodayIcon from "@mui/icons-material/Today";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";

import v1 from "../../img/its-1.png";
import v2 from "../../img/its-2.png";
import v3 from "../../img/its-3.png";
import v4 from "../../img/its-3.png";
import v5 from "../../img/its-3.png";
import CustomModal from "./CustomModal";

export default class CustomServices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      custservice: [
        {
          service_level_id: "idilevel001",
          service_level1: "Accounting Services",
          service_list: [
            {
              service_id: "idiserv001",
              service_name: "Company Formation",
              subservice_list: [
                {
                  sub_service_id: "idi000",
                  sub_service_name: "Private Limited Company",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Company Formation/Private Limited Company.svg",
                },
                {
                  sub_service_id: "idi001",
                  sub_service_name: "LLP",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Company Formation/LLP.svg",
                },
                {
                  sub_service_id: "idi002",
                  sub_service_name: "Partnership Firm",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Company Formation/Partnership Firm.svg",
                },
                {
                  sub_service_id: "idi003",
                  sub_service_name: "One Person Company",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Company Formation/One Person Company.svg",
                },
                {
                  sub_service_id: "idi004",
                  sub_service_name: "Sole Proprietorship",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Company Formation/Sole Proprietorship.svg",
                },
              ],
            },
            {
              service_id: "idiserv002",
              service_name: "Registration",
              subservice_list: [
                {
                  sub_service_id: "idi005",
                  sub_service_name: "Startup India Registration",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Registration/Startup India Registration.svg",
                },
                {
                  sub_service_id: "idi006",
                  sub_service_name: "MSME Registration",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Registration/MSME Registration.svg",
                },
                {
                  sub_service_id: "idi0010",
                  sub_service_name: "Import/Export Code",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Registration/Import Export Code.svg",
                },
              ],
            },

            {
              service_id: "idiserv004",
              service_name: "GST",
              subservice_list: [
                {
                  sub_service_id: "idi0013",
                  sub_service_name: "GST Registration",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/GST/GST Registration.svg",
                },
                {
                  sub_service_id: "idi0014",
                  sub_service_name: "GST Return Filing",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/GST/Return Filing.svg",
                },
                {
                  sub_service_id: "idi0015",
                  sub_service_name: "GST Certification Work",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/GST/GST Certification Work.svg",
                },
                {
                  sub_service_id: "idi0016",
                  sub_service_name: "Income Tax Return",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/GST/Income Tax Return.svg",
                },
                {
                  sub_service_id: "idi0017",
                  sub_service_name: "TDS Return Filing",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/GST/TDS Return Filing.svg",
                },
              ],
            },
            {
              service_id: "idiserv005",
              service_name: "Accounting",
              subservice_list: [
                {
                  sub_service_id: "idi0018",
                  sub_service_name: "Financial Statement Preparation",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Accounting/Financial Statement preparation.svg",
                },
                {
                  sub_service_id: "idi0019",
                  sub_service_name: "Review of Accounts and Consultancy",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Accounting/Review of accounts and consultancy.svg",
                },
                {
                  sub_service_id: "idi0020",
                  sub_service_name: "Internal Audit",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Accounting/Internal Audit.svg",
                },
              ],
            },
            {
              service_id: "idiserv007",
              service_name: "Income Tax Filing",
              subservice_list: [
                {
                  sub_service_id: "idi0025",
                  sub_service_name: "Return Filing for Individual",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Income Tax Filing/Return filing for Individual.svg",
                },
                {
                  sub_service_id: "idi0026",
                  sub_service_name: "Return Filing for Partnership Firms",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Income Tax Filing/Return filing for Partnership firms.svg",
                },
                {
                  sub_service_id: "idi0027",
                  sub_service_name: "Return Filing for Private Company",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Income Tax Filing/Return filing for Private company.svg",
                },
              ],
            },
            {
              service_id: "idiserv008",
              service_name: "Form Filing",
              subservice_list: [
                {
                  sub_service_id: "idi0028",
                  sub_service_name: "TDS/TCS Return",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Form Filing/TDSTCS return.svg",
                },
                {
                  sub_service_id: "idi0029",
                  sub_service_name: "Filing of Form 15CA/CB",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Form Filing/Filing of Form 15CA.svg",
                },
                {
                  sub_service_id: "idi0030",
                  sub_service_name: "Forma Filed under IT Act",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Form Filing/Forma Filed under IT Act.svg",
                },
                {
                  sub_service_id: "idi0031",
                  sub_service_name: "MSME Loan Approval",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Form Filing/MSME loan approval.svg",
                },
                {
                  sub_service_id: "idi0032",
                  sub_service_name: "IEC Related Work",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Form Filing/IEC related work.svg",
                },
                {
                  sub_service_id: "idi0033",
                  sub_service_name: "SEIS Related Work",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Form Filing/SEIS related work.svg",
                },
                {
                  sub_service_id: "idi0034",
                  sub_service_name: "SEIS License Facilitation",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Form Filing/SEIS license facilitation.svg",
                },
              ],
            },
            {
              service_id: "idiserv0010",
              service_name: "Consultation",
              subservice_list: [
                {
                  sub_service_id: "idi0043",
                  sub_service_name: "Consulting Other",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Consultation/Consulting other.svg",
                },
                {
                  sub_service_id: "idi0044",
                  sub_service_name: "Public Relation Consulting",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Consultation/Public Relation Consulting.svg",
                },
                {
                  sub_service_id: "idi0045",
                  sub_service_name: "SOP Standard Operating Process",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Consultation/SOP Standard Operating Process.svg",
                },
              ],
            },
          ],
        },
        {
          service_level_id: "idilevel002",
          service_level1: "Legal",
          service_list: [
            {
              service_id: "idiserv0018",
              service_name: "Registration",
              subservice_list: [
                {
                  sub_service_id: "idi007",
                  sub_service_name: "Food License (FSSAI)",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Registration/Food License (FSSAI).svg",
                },
                {
                  sub_service_id: "idi008",
                  sub_service_name: "Trade License",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Registration/Trade License.svg",
                },
                {
                  sub_service_id: "idi009",
                  sub_service_name: "Professional Tax Registration",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Registration/Professional Tax Registration.svg",
                },
                {
                  sub_service_id: "idi0011",
                  sub_service_name: "Digital Signature",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Registration/Digital Signature.svg",
                },
              ],
            },
            {
              service_id: "idiserv003",
              service_name: "Trademark",
              subservice_list: [
                {
                  sub_service_id: "idi0012",
                  sub_service_name: "Trademark Search Report",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Trademark/Trademark Search Report.svg",
                },
                {
                  sub_service_id: "idi0013",
                  sub_service_name: "Trademark Registration",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Trademark/Trademark Registration.svg",
                },
                {
                  sub_service_id: "idi0014",
                  sub_service_name: "Trademark Objection Reply",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Trademark/Trademark Objection Reply.svg",
                },
                {
                  sub_service_id: "idi0015",
                  sub_service_name: "Trademark Opposition",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Trademark/Trademark Opposition.svg",
                },
                {
                  sub_service_id: "idi0016",
                  sub_service_name: "Trademark Renewal",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Trademark/Trademark Renewal.svg",
                },
                {
                  sub_service_id: "idi0017",
                  sub_service_name: "Trademark Assignment",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Trademark/Trademark Assignment.svg",
                },
                {
                  sub_service_id: "idi0018",
                  sub_service_name: "Trademark Tracking",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Trademark/Trademark Tracking.svg",
                },
              ],
            },
            {
              service_id: "idiserv006",
              service_name: "Agreements I",
              subservice_list: [
                {
                  sub_service_id: "idi0021",
                  sub_service_name: "Drafting of Partnership Deed",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Agreements/Drafting of Partnership Deed.svg",
                },
                {
                  sub_service_id: "idi0022",
                  sub_service_name: "Modification in Partnership Deed",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Agreements/Modification in Partnership Deed.svg",
                },
                {
                  sub_service_id: "idi0023",
                  sub_service_name: "Gift Deed",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Agreements/Gift Deed.svg",
                },
                {
                  sub_service_id: "idi0024",
                  sub_service_name: "Other Legal Agreements",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Agreements/Other legal agreements.svg",
                },
              ],
            },
            {
              service_id: "idiserv009",
              service_name: "Agreements II",
              subservice_list: [
                {
                  sub_service_id: "idi0035",
                  sub_service_name: "Website Terms and Condition",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Agreements2/Website terms and condition.svg",
                },
                {
                  sub_service_id: "idi0036",
                  sub_service_name: "E Commerce Website Terms and Condition",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Agreements2/E Commerce website terms and condition.svg",
                },
                {
                  sub_service_id: "idi0037",
                  sub_service_name: "Startup Agreements with Investor",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Agreements2/Startup agreements with investor.svg",
                },
                {
                  sub_service_id: "idi0038",
                  sub_service_name: "Co Founder Agreement",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Agreements2/Co founder agreement.svg",
                },
                {
                  sub_service_id: "idi0039",
                  sub_service_name: "Cyber Reporting of Fraud",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Agreements2/Cyber Reporting of fraud.svg",
                },
                {
                  sub_service_id: "idi0040",
                  sub_service_name: "Franchisee Agreement",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Agreements2/Franchisee agreement.svg",
                },
                {
                  sub_service_id: "idi0041",
                  sub_service_name: "Any Other Agreements",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Agreements2/Any other agreements.svg",
                },
                {
                  sub_service_id: "idi0042",
                  sub_service_name: "NDA and Non Compete",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Agreements2/NDA and Non Compete.svg",
                },
              ],
            },
            {
              service_id: "idiserv0011",
              service_name: "Franchisee",
              subservice_list: [
                {
                  sub_service_id: "idi0046",
                  sub_service_name: "Franchisee Agreement",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Franchisee/Franchisee Agreement.svg",
                },
                {
                  sub_service_id: "idi0047",
                  sub_service_name: "Franchisee Model",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Franchisee/Franchisee Model.svg",
                },
                {
                  sub_service_id: "idi0048",
                  sub_service_name: "IPR - Trademarks",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Franchisee/IPR - Trademarks.svg",
                },
                {
                  sub_service_id: "idi0049",
                  sub_service_name: "Technology Agreement",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Franchisee/Technology Agreement.svg",
                },
                {
                  sub_service_id: "idi0050",
                  sub_service_name: "Technology Integration",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Franchisee/Technology Integration.svg",
                },
                {
                  sub_service_id: "idi0051",
                  sub_service_name: "Franchisee SOP",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Franchisee/Franchisee SOP.svg",
                },
                {
                  sub_service_id: "idi0052",
                  sub_service_name: "Risk Calculation",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Franchisee/Risk Calculation.svg",
                },
                {
                  sub_service_id: "idi0053",
                  sub_service_name: "Master Franchisee Agreement",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Franchisee/Master Franchisee Agreement.svg",
                },
                {
                  sub_service_id: "idi0054",
                  sub_service_name: "Franchisee PER PPT",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Franchisee/Franchisee PER PPT.svg",
                },
              ],
            },
            {
              service_id: "idiserv0012",
              service_name: "Copyright",
              subservice_list: [
                {
                  sub_service_id: "idi0055",
                  sub_service_name:
                    "For a license to Republish a Literary, Dramatic, Musical or Artistic work (Sections 31, 31A,31B* and 32A)",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Copyright/For a license to republish a Literary, Dramatic, Musical or Artistic work.svg",
                },
                {
                  sub_service_id: "idi0056",
                  sub_service_name:
                    "For a License to Communicate an any Work to the Public by Broadcast(Section 31(1)(b))",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Copyright/For a license to communicate an any work to the public by Broadcast.svg",
                },
                {
                  sub_service_id: "idi0057",
                  sub_service_name:
                    "For License to Republish a Cinematograph Film (Section 31)",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Copyright/Cinematograph Film.svg",
                },
                {
                  sub_service_id: "idi0058",
                  sub_service_name:
                    "For a License to Republish a Sound Recording (Section 31)",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Copyright/sound recording.svg",
               },
                {
                  sub_service_id: "idi0059",
                  sub_service_name:
                    "For a License to Perform any Work in Public (Section 31)",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Copyright/For a license to perform any work in public (Section 31).svg",
                },
                {
                  sub_service_id: "idi0060",
                  sub_service_name:
                    "For a License to Publish or Communicate to the Public the Work or Translation (Section 31A)",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Copyright/For a license to publish or communicate to the public the work or translation (Section 31A).svg",
                },
                {
                  sub_service_id: "idi0061",
                  sub_service_name:
                    "For a License to Publish any Work in any Format useful for Person with Disability (Section 31 B)",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Copyright/For a license to publish any work in any format useful for person with disability (Section 31 B).svg",
                },
                {
                  sub_service_id: "idi0062",
                  sub_service_name:
                    "For an Application for a License to Produce and Publish a Translation of a Literary or Dramatic work in any Language (Section 32 & 32-A )",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Copyright/Dramatic work.svg",
                },
              ],
            },
            {
              service_id: "idiserv0013",
              service_name: "Patent",
              subservice_list: [
                {
                  sub_service_id: "idi0055",
                  sub_service_name: "Patents Search",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Patent/Patents Search.svg",
                },
                {
                  sub_service_id: "idi0056",
                  sub_service_name: "Patent Consulting",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Patent/Patent Consulting.svg",
                },
                {
                  sub_service_id: "idi0057",
                  sub_service_name: "Patent Filing",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Patent/Patent Filling.svg",
                },
              ],
            },
            {
              service_id: "idiserv0014",
              service_name: "Design",
              subservice_list: [
                {
                  sub_service_id: "idi0058",
                  sub_service_name: "Design Registration",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Design/Design Registration.svg",
                },
              ],
            },
            {
              service_id: "idiserv0015",
              service_name: "Company HR",
              subservice_list: [
                {
                  sub_service_id: "idi0059",
                  sub_service_name: "NDA",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Company HR/NDA.svg",
                },
                {
                  sub_service_id: "idi0060",
                  sub_service_name: "Company Structuring",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Company HR/Company Structuring.svg",
                },
                {
                  sub_service_id: "idi0061",
                  sub_service_name: "Non Competing",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Company HR/Non Competing.svg",
                },
                {
                  sub_service_id: "idi0062",
                  sub_service_name: "Employees Agreement",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Company HR/Employees agreement.svg",
                },
                {
                  sub_service_id: "idi0063",
                  sub_service_name: "Goal and Process Setting",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Company HR/Goal and Process Setting.svg",
                },
                {
                  sub_service_id: "idi0064",
                  sub_service_name: "Auto Pilot and Monetiring",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/Company HR/Auto Pilot and Monetiring.svg",
                },
              ],
            },
            {
              service_id: "idiserv0017",
              service_name: "ISO",
              subservice_list: [
                {
                  sub_service_id: "idi0067",
                  sub_service_name: "NON IAF",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/ISO/iso.svg",
                },
                {
                  sub_service_id: "idi0069",
                  sub_service_name: "ISO 14001",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/ISO/ISO 14001.svg",
                },
                {
                  sub_service_id: "idi0070",
                  sub_service_name: "ISO 18001",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/ISO/ISO 18001.svg",
                },
                {
                  sub_service_id: "idi0071",
                  sub_service_name: "ISO 22000",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/ISO/ISO 22000.svg",
                },
                {
                  sub_service_id: "idi0072",
                  sub_service_name: "ISO 27001",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/ISO/ISO 27001.svg",
                },
                {
                  sub_service_id: "idi0073",
                  sub_service_name: "ISO 13485 Medical Device",
                  sub_service_image:
                    "../../Icons/Services/Accounting & Legal Advisory/ISO/Medical Device.svg",
                },
              ],
            },
          ],
        },
        {
          service_level_id: "idilevel003",
          service_level1: "Branding",
          service_list: [
            {
              service_id: "idiserv0019",
              service_name: "Logo Design",
              subservice_list: [
                {
                  sub_service_id: "idi0074",
                  sub_service_name: "Emblem logos",
                  sub_service_image:
                    "../../Icons/Services/Branding/Logo Design/Emblem logos.svg",
                },
                {
                  sub_service_id: "idi0075",
                  sub_service_name: "Pictorial Mark Logos",
                  sub_service_image:
                    "../../Icons/Services/Branding/Logo Design/Pictorial mark logos.svg",
                },
                {
                  sub_service_id: "idi0076",
                  sub_service_name: "Logotypes",
                  sub_service_image:
                    "../../Icons/Services/Branding/Logo Design/Logotypes.svg",
                },
                {
                  sub_service_id: "idi0077",
                  sub_service_name: "Lettermark Logos",
                  sub_service_image:
                    "../../Icons/Services/Branding/Logo Design/Lettermark logos.svg",
                },
                {
                  sub_service_id: "idi0078",
                  sub_service_name: "Abstract Logos",
                  sub_service_image:
                    "../../Icons/Services/Branding/Logo Design/Abstract logos.svg",
                },
                {
                  sub_service_id: "idi0079",
                  sub_service_name: "Mascot Logo",
                  sub_service_image:
                    "../../Icons/Services/Branding/Logo Design/Mascot logo.svg",
                },
                {
                  sub_service_id: "idi0080",
                  sub_service_name: "Combination Marks Logo",
                  sub_service_image:
                    "../../Icons/Services/Branding/Logo Design/Combination logo marks.svg",
                },
              ],
            },
            {
              service_id: "idiserv0020",
              service_name: "Stationary",
              subservice_list: [
                {
                  sub_service_id: "idi0081",
                  sub_service_name: "Business Card Design",
                  sub_service_image:
                    "../../Icons/Services/Branding/Stationary/Business Card Design.svg",
                },
                {
                  sub_service_id: "idi0082",
                  sub_service_name: "Letterhead Design",
                  sub_service_image:
                    "../../Icons/Services/Branding/Stationary/Letterhead Design.svg",
                },
                {
                  sub_service_id: "idi0083",
                  sub_service_name: "Envelope Design",
                  sub_service_image:
                    "../../Icons/Services/Branding/Stationary/Envelope Design.svg",
                },
                {
                  sub_service_id: "idi0084",
                  sub_service_name: "Flyer Design",
                  sub_service_image:
                    "../../Icons/Services/Branding/Stationary/Flyer Design.svg",
                },
                {
                  sub_service_id: "idi0085",
                  sub_service_name: "Brochure Design",
                  sub_service_image:
                    "../../Icons/Services/Branding/Stationary/Brochure Design.svg",
                },
                {
                  sub_service_id: "idi0086",
                  sub_service_name: "Leaflet",
                  sub_service_image:
                    "../../Icons/Services/Branding/Stationary/Leaflet.svg",
                },
                {
                  sub_service_id: "idi0087",
                  sub_service_name: "Catalogue Design",
                  sub_service_image:
                    "../../Icons/Services/Branding/Stationary/Catalogue Design.svg",
                },
                {
                  sub_service_id: "idi0088",
                  sub_service_name: "Booklet Design",
                  sub_service_image:
                    "../../Icons/Services/Branding/Stationary/Booklet Design.svg",
                },
              ],
            },
            {
              service_id: "idiserv0021",
              service_name: "Newsletter Design",
              subservice_list: [
                {
                  sub_service_id: "idi0089",
                  sub_service_name: "Email Template",
                  sub_service_image:
                    "../../Icons/Services/Branding/Newsletter Design/Email Template.svg",
                },
                {
                  sub_service_id: "idi0090",
                  sub_service_name: "Newspaper",
                  sub_service_image:
                    "../../Icons/Services/Branding/Newsletter Design/Newspaper.svg",
                },
                {
                  sub_service_id: "idi0091",
                  sub_service_name: "Newsletter",
                  sub_service_image:
                    "../../Icons/Services/Branding/Newsletter Design/Newsletter.svg",
                },
              ],
            },
            {
              service_id: "idiserv0022",
              service_name: "Packaging Design",
              subservice_list: [
                {
                  sub_service_id: "idi0092",
                  sub_service_name: "Label Design",
                  sub_service_image:
                    "../../Icons/Services/Branding/Packaging Design/Label Design.svg",
                },
                {
                  sub_service_id: "idi0093",
                  sub_service_name: "Customize Design",
                  sub_service_image:
                    "../../Icons/Services/Branding/Packaging Design/Customize Design.svg",
                },
                {
                  sub_service_id: "idi0094",
                  sub_service_name: "Box Design",
                  sub_service_image:
                    "../../Icons/Services/Branding/Packaging Design/Box Design.svg",
                },
              ],
            },
            {
              service_id: "idiserv0023",
              service_name: "Book Cover Design",
              subservice_list: [
                {
                  sub_service_id: "idi0095",
                  sub_service_name: "Photographic",
                  sub_service_image:
                    "../../Icons/Services/Branding/Book Cover Design/Photograph.svg",
                },
                {
                  sub_service_id: "idi0096",
                  sub_service_name: "Illustrative",
                  sub_service_image:
                    "../../Icons/Services/Branding/Book Cover Design/Illustrative.svg",
                },
                {
                  sub_service_id: "idi0097",
                  sub_service_name: "Books",
                  sub_service_image:
                    "../../Icons/Services/Branding/Book Cover Design/Books.svg",
                },
                {
                  sub_service_id: "idi0098",
                  sub_service_name: "Paper Bags & Sacks Boxboard",
                  sub_service_image:
                    "../../Icons/Services/Branding/Book Cover Design/Paper Bags & Sacks Boxboard.svg",
                },
                {
                  sub_service_id: "idi0099",
                  sub_service_name: "Paperboard Cartoons",
                  sub_service_image:
                    "../../Icons/Services/Branding/Book Cover Design/Paperboard Cartoons.svg",
                },
                {
                  sub_service_id: "idi00100",
                  sub_service_name: "Book Cover",
                  sub_service_image:
                    "../../Icons/Services/Branding/Book Cover Design/Book Cover.svg",
                },
              ],
            },
            {
              service_id: "idiserv0024",
              service_name: "Marketing Graphics",
              subservice_list: [
                {
                  sub_service_id: "idi00101",
                  sub_service_name: "Posters",
                  sub_service_image:
                    "../../Icons/Services/Branding/Marketing & Advertising Graphic Design/Poster.svg",
                },
                {
                  sub_service_id: "idi00102",
                  sub_service_name: "Banners & Graphics",
                  sub_service_image:
                    "../../Icons/Services/Branding/Marketing & Advertising Graphic Design/Banners & Graphics.svg",
                },
                /* Hide Service */
                // {
                //   sub_service_id: "idi00103",
                //   sub_service_name: "Billboards",
                //   sub_service_image:
                //     "../../Icons/Services/Branding/Marketing & Advertising Graphic Design/Billboards.svg",
                // },
                {
                  sub_service_id: "idi00104",
                  sub_service_name: "Infographics Postcards & Flyers",
                  sub_service_image:
                    "../../Icons/Services/Branding/Marketing & Advertising Graphic Design/Infographics Postcards & Flyers.svg",
                },
                {
                  sub_service_id: "idi00105",
                  sub_service_name: "Coffee Table Book",
                  sub_service_image:
                    "../../Icons/Services/Branding/Marketing & Advertising Graphic Design/Coffee Table Book.svg",
                },
                {
                  sub_service_id: "idi00106",
                  sub_service_name: "Banners & Graphics",
                  sub_service_image:
                    "../../Icons/Services/Branding/Marketing & Advertising Graphic Design/Banners & Graphics.svg",
                },
                {
                  sub_service_id: "idi00107",
                  sub_service_name: "Presentations Banner & Retargeting Ads",
                  sub_service_image:
                    "../../Icons/Services/Branding/Marketing & Advertising Graphic Design/Presentations Banner & Retargeting ads.svg",
                },
              ],
            },
            /*Hidden Content*/
            // {
            //   service_id: "idiserv0025",
            //   service_name: "Publication Graphic Design",
            //   subservice_list: [
            //     {
            //       sub_service_id: "idi00108",
            //       sub_service_name: "Magazines & Catalogues",
            //       sub_service_image:
            //         "../../Icons/Services/Branding/Publication Graphic Design/Magazines & Catalogues.svg",
            //     },
            //     {
            //       sub_service_id: "idi00109",
            //       sub_service_name: "Directories",
            //       sub_service_image:
            //         "../../Icons/Services/Branding/Publication Graphic Design/Directories.svg",
            //     },
            //     {
            //       sub_service_id: "idi00110",
            //       sub_service_name: "Annual Report",
            //       sub_service_image:
            //         "../../Icons/Services/Branding/Publication Graphic Design/Annual Report.svg",
            //     },
            //   ],
            // },
            // {
            //   service_id: "idiserv0026",
            //   service_name: "Environment Graphic design",
            //   subservice_list: [
            //     {
            //       sub_service_id: "idi00111",
            //       sub_service_name: "Signage & trade show displays",
            //       sub_service_image:
            //         "../../Icons/Services/Branding/Environment Graphic design/Signage & trade show displays.svg",
            //     },
            //     {
            //       sub_service_id: "idi00112",
            //       sub_service_name: "Office Branding & Stadium branding",
            //       sub_service_image:
            //         "../../Icons/Services/Branding/Environment Graphic design/Office Branding & Stadium branding.svg",
            //     },
            //     {
            //       sub_service_id: "idi00113",
            //       sub_service_name: "Public Transportation navigation",
            //       sub_service_image:
            //         "../../Icons/Services/Branding/Environment Graphic design/Public Transportation navigation.svg",
            //     },
            //   ],
            // },
            // {
            //   service_id: "idiserv0027",
            //   service_name: "Signage",
            //   subservice_list: [
            //     {
            //       sub_service_id: "idi00114",
            //       sub_service_name: "Flex Designing",
            //       sub_service_image:
            //         "../../Icons/Services/Branding/Signage/Flex Designing.svg",
            //     },
            //     {
            //       sub_service_id: "idi00115",
            //       sub_service_name: "Eco Solvent Designing",
            //       sub_service_image:
            //         "../../Icons/Services/Branding/Signage/Eco Solvent Designing.svg",
            //     },
            //     {
            //       sub_service_id: "idi00116",
            //       sub_service_name: "3D Designing",
            //       sub_service_image:
            //         "../../Icons/Services/Branding/Signage/3D Designing.svg",
            //     },
            //     {
            //       sub_service_id: "idi00116",
            //       sub_service_name: "Standee",
            //       sub_service_image:
            //         "../../Icons/Services/Branding/Signage/Standee.svg",
            //     },
            //   ],
            // },
            {
              service_id: "idiserv0028",
              service_name: "Video",
              subservice_list: [
                {
                  sub_service_id: "idi00114",
                  sub_service_name: "Video Editing Footage",
                  sub_service_image:
                    "../../Icons/Services/Branding/Video/Video Editing Footage.svg",
                },
                {
                  sub_service_id: "idi00115",
                  sub_service_name: "Motion Video",
                  sub_service_image:
                    "../../Icons/Services/Branding/Video/Motion Video.svg",
                },
                {
                  sub_service_id: "idi00116",
                  sub_service_name: "White Board Video",
                  sub_service_image:
                    "../../Icons/Services/Branding/Video/White Board Video.svg",
                },
                {
                  sub_service_id: "idi00116",
                  sub_service_name: "Audio Editing Footage",
                  sub_service_image:
                    "../../Icons/Services/Branding/Video/Audio Editing Footage.svg",
                },
                {
                  sub_service_id: "idi00117",
                  sub_service_name: "Video PortFolio",
                  sub_service_image:
                    "../../Icons/Services/Branding/Video/Video PortFolio.svg",
                },
              ],
            },
            {
              service_id: "idiserv0029",
              service_name: "Social Media",
              subservice_list: [
                {
                  sub_service_id: "idi00114",
                  sub_service_name: "Post",
                  sub_service_image:
                    "../../Icons/Services/Branding/Social Media/Post.svg",
                },
                {
                  sub_service_id: "idi00115",
                  sub_service_name: "Cover Page",
                  sub_service_image:
                    "../../Icons/Services/Branding/Social Media/Cover Page.svg",
                },
                {
                  sub_service_id: "idi00116",
                  sub_service_name: "YouTube Video Thumbnail Design",
                  sub_service_image:
                    "../../Icons/Services/Branding/Social Media/You tube Video Thumbnail Design.svg",
                },
                {
                  sub_service_id: "idi00116",
                  sub_service_name: "Story Design",
                  sub_service_image:
                    "../../Icons/Services/Branding/Social Media/Story Design.svg",
                },
                {
                  sub_service_id: "idi00117",
                  sub_service_name: "Ads",
                  sub_service_image:
                    "../../Icons/Services/Branding/Social Media/Ads.svg",
                },
                {
                  sub_service_id: "idi00118",
                  sub_service_name: "Animated Social Media Post",
                  sub_service_image:
                    "../../Icons/Services/Branding/Social Media/Animated Social Media Post.svg",
                },
              ],
            },
            {
              service_id: "idiserv0030",
              service_name: "Presentations",
              subservice_list: [
                {
                  sub_service_id: "idi00119",
                  sub_service_name: "Corporate Presentation",
                  sub_service_image:
                    "../../Icons/Services/Branding/Presentations/Corporate Presentation.svg",
                },
                {
                  sub_service_id: "idi00120",
                  sub_service_name: "Multimedia Presentation Design",
                  sub_service_image:
                    "../../Icons/Services/Branding/Presentations/Multimedia Presentation Design.svg",
                },
                {
                  sub_service_id: "idi00121",
                  sub_service_name: "Presentation Folder Design",
                  sub_service_image:
                    "../../Icons/Services/Branding/Presentations/Presentation Folder Design.svg",
                },
                {
                  sub_service_id: "idi00122",
                  sub_service_name: "Story Boarding",
                  sub_service_image:
                    "../../Icons/Services/Branding/Presentations/Story Boarding.svg",
                },
              ],
            },
            {
              service_id: "idiserv0032",
              service_name: "Ecommerce",
              subservice_list: [
                {
                  sub_service_id: "idi00125",
                  sub_service_name: "Banner Design",
                  sub_service_image:
                    "../../Icons/Services/Branding/Ecommerce/Ecommerce banner.svg",
                },
                {
                  sub_service_id: "idi00126",
                  sub_service_name: "Photo Editing",
                  sub_service_image:
                    "../../Icons/Services/Branding/Ecommerce/Photo Editing.svg",
                },
                {
                  sub_service_id: "idi00127",
                  sub_service_name: "Product Modeling",
                  sub_service_image:
                    "../../Icons/Services/Branding/Ecommerce/Product Modeling.svg",
                },
                {
                  sub_service_id: "idi00128",
                  sub_service_name: "Product Editing",
                  sub_service_image:
                    "../../Icons/Services/Branding/Ecommerce/Product Editing.svg",
                },
                {
                  sub_service_id: "idi00129",
                  sub_service_name: "Video Editing",
                  sub_service_image:
                    "../../Icons/Services/Branding/Ecommerce/Video Editing.svg",
                },
              ],
            },
            /*Hidden Content*/
            // {
            //   service_id: "idiserv0031",
            //   service_name: "Others",
            //   subservice_list: [
            //     {
            //       sub_service_id: "idi00123",
            //       sub_service_name: "Custom Print Designs",
            //       sub_service_image:
            //         "../../Icons/Services/Branding/Others/Custom Print Designs.svg",
            //     },
            //     {
            //       sub_service_id: "idi00124",
            //       sub_service_name: "Corporate Gifts",
            //       sub_service_image:
            //         "../../Icons/Services/Branding/Others/Corporate Gifts.svg",
            //     },
            //   ],
            // },
          ],
        },
        {
          service_level_id: "idilevel004",
          service_level1: "IT Services",
          service_list: [
            {
              service_id: "idiserv0044",
              service_name: "IT Solutioning",
              subservice_list: [
                {
                  sub_service_id: "idi00205",
                  sub_service_name: "Web Designing",
                  sub_service_image:
                    "../../Icons/Services/IT Service/IT Solutioning/web-design.svg",
                },
                {
                  sub_service_id: "idi00206",
                  sub_service_name: "Web Application Development",
                  sub_service_image:
                    "../../Icons/Services/IT Service/IT Solutioning/Web Application development.svg",
                },
                {
                  sub_service_id: "idi00207",
                  sub_service_name: "Mobile app Development",
                  sub_service_image:
                    "../../Icons/Services/IT Service/IT Solutioning/Mobile app development.svg",
                },
                {
                  sub_service_id: "idi00208",
                  sub_service_name: "Redesign your Traditional Website",
                  sub_service_image:
                    "../../Icons/Services/IT Service/IT Solutioning/Redesign you traditional website.svg",
                },
                {
                  sub_service_id: "idi00209",
                  sub_service_name: "End-to-End Testing",
                  sub_service_image:
                    "../../Icons/Services/IT Service/IT Solutioning/End to End Testing.svg",
                },
                {
                  sub_service_id: "idi00210",
                  sub_service_name: "Automation Testing",
                  sub_service_image:
                    "../../Icons/Services/IT Service/IT Solutioning/Automation Testing.svg",
                },
              ],
            },
            {
              service_id: "idiserv0041",
              service_name: "API Services",
              subservice_list: [
                {
                  sub_service_id: "idi00193",
                  sub_service_name: "API Integration",
                  sub_service_image:
                    "../../Icons/Services/IT Service/API Services/API integration.svg",
                },
                {
                  sub_service_id: "idi00194",
                  sub_service_name: "Create APIs for Your Existing Project",
                  sub_service_image:
                    "../../Icons/Services/IT Service/API Services/Create APIs for your existing project.svg",
                },
                {
                  sub_service_id: "idi00195",
                  sub_service_name: "Expose API to Clients/Business",
                  sub_service_image:
                    "../../Icons/Services/IT Service/API Services/Expose API to clients business.svg",
                },
                {
                  sub_service_id: "idi00196",
                  sub_service_name: "Expand API Set",
                  sub_service_image:
                    "../../Icons/Services/IT Service/API Services/Expand API set.svg",
                },
                {
                  sub_service_id: "idi00197",
                  sub_service_name: "Web Services to REST API Migration",
                  sub_service_image:
                    "../../Icons/Services/IT Service/API Services/Web Services to REST API Migration.svg",
                },
              ],
            },
            {
              service_id: "idiserv0042",
              service_name: "Content Management System",
              subservice_list: [
                {
                  sub_service_id: "idi00198",
                  sub_service_name: "Database Management",
                  sub_service_image:
                    "../../Icons/Services/IT Service/Content management system/Database Management.svg",
                },
                {
                  sub_service_id: "idi00199",
                  sub_service_name: "Content Studio",
                  sub_service_image:
                    "../../Icons/Services/IT Service/Content management system/Content Studio.svg",
                },
                {
                  sub_service_id: "idi00200",
                  sub_service_name: "SharePoint",
                  sub_service_image:
                    "../../Icons/Services/IT Service/Content management system/sharePoint.svg",
                },
              ],
            },
            {
              service_id: "idiserv0043",
              service_name: "Cloud Services",
              subservice_list: [
                {
                  sub_service_id: "idi00201",
                  sub_service_name: "Cloud Advisory Services",
                  sub_service_image:
                    "../../Icons/Services/IT Service/Cloud Services/Cloud advisory services.svg",
                },
                {
                  sub_service_id: "idi00202",
                  sub_service_name: "Cloud Migration",
                  sub_service_image:
                    "../../Icons/Services/IT Service/Cloud Services/Cloud migration.svg",
                },
                {
                  sub_service_id: "idi00203",
                  sub_service_name: "Cloud Modernization",
                  sub_service_image:
                    "../../Icons/Services/IT Service/Cloud Services/Cloud modernization.svg",
                },
                {
                  sub_service_id: "idi00204",
                  sub_service_name: "Cloud Native Application Development",
                  sub_service_image:
                    "../../Icons/Services/IT Service/Cloud Services/Cloud native application development.svg",
                },
              ],
            },
          ],
        },
        {
          service_level_id: "idilevel005",
          service_level1: "Marketing",
          service_list: [
            /*Hidden Content*/
            // {
            //   service_id: "idiserv0033",
            //   service_name: "OnGround Marketing",
            //   subservice_list: [
            //     {
            //       sub_service_id: "idi00131",
            //       sub_service_name: "Events",
            //       sub_service_image:
            //         "../../Icons/Services/Marketing/OnGround Marketing/Events.svg",
            //     },
            //     {
            //       sub_service_id: "idi00132",
            //       sub_service_name: "Brand Openings",
            //       sub_service_image:
            //         "../../Icons/Services/Marketing/OnGround Marketing/Brand Openings.svg",
            //     },
            //     {
            //       sub_service_id: "idi00133",
            //       sub_service_name: "Corporate Events",
            //       sub_service_image:
            //         "../../Icons/Services/Marketing/OnGround Marketing/Corporate Events.svg",
            //     },
            //     {
            //       sub_service_id: "idi00134",
            //       sub_service_name: "Channel partner events",
            //       sub_service_image:
            //         "../../Icons/Services/Marketing/OnGround Marketing/Channel partner events.svg",
            //     },
            //     {
            //       sub_service_id: "idi00135",
            //       sub_service_name: "Confrences",
            //       sub_service_image:
            //         "../../Icons/Services/Marketing/OnGround Marketing/Confrences.svg",
            //     },
            //     {
            //       sub_service_id: "idi00136",
            //       sub_service_name: "Exhibitions",
            //       sub_service_image:
            //         "../../Icons/Services/Marketing/OnGround Marketing/Exhibitions.svg",
            //     },
            //     {
            //       sub_service_id: "idi00137",
            //       sub_service_name: "Exprential Marketing",
            //       sub_service_image:
            //         "../../Icons/Services/Marketing/OnGround Marketing/Exprential Marketing.svg",
            //     },
            //     {
            //       sub_service_id: "idi00138",
            //       sub_service_name: "Cross Promotions",
            //       sub_service_image:
            //         "../../Icons/Services/Marketing/OnGround Marketing/Cross Promotions.svg",
            //     },
            //     {
            //       sub_service_id: "idi00139",
            //       sub_service_name: "Product Launches",
            //       sub_service_image:
            //         "../../Icons/Services/Marketing/OnGround Marketing/Product Launches.svg",
            //     },
            //     {
            //       sub_service_id: "idi00140",
            //       sub_service_name: "Seminars",
            //       sub_service_image:
            //         "../../Icons/Services/Marketing/OnGround Marketing/Seminars.svg",
            //     },
            //     {
            //       sub_service_id: "idi00141",
            //       sub_service_name: "B2B Marketing",
            //       sub_service_image:
            //         "../../Icons/Services/Marketing/OnGround Marketing/B2B Marketing.svg",
            //     },
            //     {
            //       sub_service_id: "idi00142",
            //       sub_service_name: "B2C Marketing",
            //       sub_service_image:
            //         "../../Icons/Services/Marketing/OnGround Marketing/B2C.svg",
            //     },
            //   ],
            // },
            {
              service_id: "idiserv0034",
              service_name: "Digital Marketing Services",
              subservice_list: [
                {
                  sub_service_id: "idi00143",
                  sub_service_name: "Social Media Mangement",
                  sub_service_image:
                    "../../Icons/Services/Marketing/Digital Marketing Services/social-media.svg",
                },
                {
                  sub_service_id: "idi00144",
                  sub_service_name: "Campaign Management",
                  sub_service_image:
                    "../../Icons/Services/Marketing/Digital Marketing Services/Campaign Management.svg",
                },
                {
                  sub_service_id: "idi00145",
                  sub_service_name: "Facebook Marketing",
                  sub_service_image:
                    "../../Icons/Services/Marketing/Digital Marketing Services/Facebook Marketing.svg",
                },
                {
                  sub_service_id: "idi00146",
                  sub_service_name: "Influencer Marketing",
                  sub_service_image:
                    "../../Icons/Services/Marketing/Digital Marketing Services/Influencer Marketing.svg",
                },
                {
                  sub_service_id: "idi00147",
                  sub_service_name: "Instagram Marketing",
                  sub_service_image:
                    "../../Icons/Services/Marketing/Digital Marketing Services/Instagram Marketing.svg",
                },
                {
                  sub_service_id: "idi00148",
                  sub_service_name: "LinkedIn Marketing",
                  sub_service_image:
                    "../../Icons/Services/Marketing/Digital Marketing Services/LinkedIn Marketing.svg",
                },
                {
                  sub_service_id: "idi00149",
                  sub_service_name: "Pinterest Marketing",
                  sub_service_image:
                    "../../Icons/Services/Marketing/Digital Marketing Services/Pintrest Marketing.svg",
                },
              ],
            },
            {
              service_id: "idiserv0035",
              service_name: "Content Marketing",
              subservice_list: [
                {
                  sub_service_id: "idi00150",
                  sub_service_name: "Product Descriptions",
                  sub_service_image:
                    "../../Icons/Services/Marketing/Content Marketing/Product Descriptions.svg",
                },
                {
                  sub_service_id: "idi00151",
                  sub_service_name: "Case Studies",
                  sub_service_image:
                    "../../Icons/Services/Marketing/Content Marketing/Case Studies.svg",
                },
                {
                  sub_service_id: "idi00152",
                  sub_service_name: "Blog Writing",
                  sub_service_image:
                    "../../Icons/Services/Marketing/Content Marketing/Blog Writing.svg",
                },
                {
                  sub_service_id: "idi00153",
                  sub_service_name: "Brochure Content",
                  sub_service_image:
                    "../../Icons/Services/Marketing/Content Marketing/Brochure Content.svg",
                },
                {
                  sub_service_id: "idi00154",
                  sub_service_name: "Social Media Post",
                  sub_service_image:
                    "../../Icons/Services/Marketing/Content Marketing/Social Media Post.svg",
                },
                {
                  sub_service_id: "idi00155",
                  sub_service_name: "Press Release Writing",
                  sub_service_image:
                    "../../Icons/Services/Marketing/Content Marketing/Press Release Writing.svg",
                },
                {
                  sub_service_id: "idi00156",
                  sub_service_name: "Article Writing",
                  sub_service_image:
                    "../../Icons/Services/Marketing/Content Marketing/Article Writing.svg",
                },
                {
                  sub_service_id: "idi00157",
                  sub_service_name: "Ghost Writing",
                  sub_service_image:
                    "../../Icons/Services/Marketing/Content Marketing/Ghost Writing.svg",
                },
                {
                  sub_service_id: "idi00158",
                  sub_service_name: "Social Media",
                  sub_service_image:
                    "../../Icons/Services/Marketing/Content Marketing/Social Media.svg",
                },
                {
                  sub_service_id: "idi00159",
                  sub_service_name: "Google ads Content",
                  sub_service_image:
                    "../../Icons/Services/Marketing/Content Marketing/Google ads Content.svg",
                },
                {
                  sub_service_id: "idi00160",
                  sub_service_name: "E-mail Marketing Content",
                  sub_service_image:
                    "../../Icons/Services/Marketing/Content Marketing/E-mail Marketing Content.svg",
                },
                {
                  sub_service_id: "idi00161",
                  sub_service_name: "Video Script",
                  sub_service_image:
                    "../../Icons/Services/Marketing/Content Marketing/Video Script.svg",
                },
                {
                  sub_service_id: "idi00162",
                  sub_service_name: "B2B Content Writing",
                  sub_service_image:
                    "../../Icons/Services/Marketing/Content Marketing/B2B Content Writing.svg",
                },
                {
                  sub_service_id: "idi00163",
                  sub_service_name: "B2C Content Writing",
                  sub_service_image:
                    "../../Icons/Services/Marketing/Content Marketing/B2C Content Writing.svg",
                },
              ],
            },
            {
              service_id: "idiserv0036",
              service_name: "Email Marketing",
              subservice_list: [
                {
                  sub_service_id: "idi00164",
                  sub_service_name: "Email Automation & Journey",
                  sub_service_image:
                    "../../Icons/Services/Marketing/Email Marketing/Email Automation.svg",
                },
                {
                  sub_service_id: "idi00165",
                  sub_service_name: "Email Campaigns",
                  sub_service_image:
                    "../../Icons/Services/Marketing/Email Marketing/Email Campaigns.svg",
                },
                {
                  sub_service_id: "idi00166",
                  sub_service_name: "Email Template Production",
                  sub_service_image:
                    "../../Icons/Services/Marketing/Email Marketing/Email Template Production.svg",
                },
                {
                  sub_service_id: "idi00167",
                  sub_service_name: "Email Hosting",
                  sub_service_image:
                    "../../Icons/Services/Marketing/Email Marketing/Email Hosting.svg",
                },
              ],
            },
            {
              service_id: "idiserv0037",
              service_name: "PPC/Paid Ads",
              subservice_list: [
                {
                  sub_service_id: "idi00168",
                  sub_service_name: "Google Ads Remarketing",
                  sub_service_image:
                    "../../Icons/Services/Marketing/PPC Paid Ads/Google Ads Remarketing.svg",
                },
                {
                  sub_service_id: "idi00169",
                  sub_service_name: "Google Maps Optimization",
                  sub_service_image:
                    "../../Icons/Services/Marketing/PPC Paid Ads/Google Maps Optimization.svg",
                },
                {
                  sub_service_id: "idi00170",
                  sub_service_name: "Google Ads",
                  sub_service_image:
                    "../../Icons/Services/Marketing/PPC Paid Ads/Google Ads.svg",
                },
                {
                  sub_service_id: "idi00171",
                  sub_service_name: "Facebook Ads",
                  sub_service_image:
                    "../../Icons/Services/Marketing/PPC Paid Ads/Facebook Ads.svg",
                },
                {
                  sub_service_id: "idi00172",
                  sub_service_name: "Instagram Ads",
                  sub_service_image:
                    "../../Icons/Services/Marketing/PPC Paid Ads/Instagram Ads.svg",
                },
                {
                  sub_service_id: "idi00173",
                  sub_service_name: "LinkedIn Ads",
                  sub_service_image:
                    "../../Icons/Services/Marketing/PPC Paid Ads/LinkedIn Ads.svg",
                },
                {
                  sub_service_id: "idi00174",
                  sub_service_name: "Other Ads",
                  sub_service_image:
                    "../../Icons/Services/Marketing/PPC Paid Ads/Other Ads.svg",
                },
                {
                  sub_service_id: "idi00175",
                  sub_service_name: "Lead Generation",
                  sub_service_image:
                    "../../Icons/Services/Marketing/PPC Paid Ads/Lead Generation.svg",
                },
              ],
            },
            {
              service_id: "idiserv0038",
              service_name: "Inbound Marketing",
              subservice_list: [
                {
                  sub_service_id: "idi00176",
                  sub_service_name: "Inbound Marketing",
                  sub_service_image:
                    "../../Icons/Services/Marketing/inbound Marketing.svg",
                },
              ],
            },
            {
              service_id: "idiserv0039",
              service_name: "Outbound Marketing",
              subservice_list: [
                {
                  sub_service_id: "idi00177",
                  sub_service_name: "Outbound Marketing",
                  sub_service_image:
                    "../../Icons/Services/Marketing/Outbound Marketing.svg",
                },
              ],
            },
            {
              service_id: "idiserv0040",
              service_name: "SEO",
              subservice_list: [
                {
                  sub_service_id: "idi00178",
                  sub_service_name: "Heat Map",
                  sub_service_image:
                    "../../Icons/Services/Marketing/SEO Link Building/Heat Map.svg",
                },
                {
                  sub_service_id: "idi00179",
                  sub_service_name: "Keyword Research",
                  sub_service_image:
                    "../../Icons/Services/Marketing/SEO Link Building/Keyword Research.svg",
                },
                {
                  sub_service_id: "idi00180",
                  sub_service_name: "Competetive Analysis",
                  sub_service_image:
                    "../../Icons/Services/Marketing/SEO Link Building/Competetive Analysis.svg",
                },
                {
                  sub_service_id: "idi00181",
                  sub_service_name: "On Page Optimization",
                  sub_service_image:
                    "../../Icons/Services/Marketing/SEO Link Building/On Page Optimization.svg",
                },
                {
                  sub_service_id: "idi00182",
                  sub_service_name: "Content SEO",
                  sub_service_image:
                    "../../Icons/Services/Marketing/SEO Link Building/Content SEO.svg",
                },
                {
                  sub_service_id: "idi00183",
                  sub_service_name: "Social Media Optimization",
                  sub_service_image:
                    "../../Icons/Services/Marketing/SEO Link Building/Social Media Optimization.svg",
                },
                {
                  sub_service_id: "idi00184",
                  sub_service_name: "SEO Report",
                  sub_service_image:
                    "../../Icons/Services/Marketing/SEO Link Building/SEO Report.svg",
                },
                {
                  sub_service_id: "idi00185",
                  sub_service_name: "Tracking",
                  sub_service_image:
                    "../../Icons/Services/Marketing/SEO Link Building/Tracking.svg",
                },
                {
                  sub_service_id: "idi00186",
                  sub_service_name: "Consultation",
                  sub_service_image:
                    "../../Icons/Services/Marketing/SEO Link Building/Consultation.svg",
                },
                {
                  sub_service_id: "idi00187",
                  sub_service_name: "Technical Analysis",
                  sub_service_image:
                    "../../Icons/Services/Marketing/SEO Link Building/Technical Analysis.svg",
                },
                {
                  sub_service_id: "idi00188",
                  sub_service_name: "Location Based Optimization",
                  sub_service_image:
                    "../../Icons/Services/Marketing/SEO Link Building/Location Based Optimization.svg",
                },
                {
                  sub_service_id: "idi00189",
                  sub_service_name: "Local SEO Optimization",
                  sub_service_image:
                    "../../Icons/Services/Marketing/SEO Link Building/Local SEO Optimization.svg",
                },
                {
                  sub_service_id: "idi00190",
                  sub_service_name: "Google My Business",
                  sub_service_image:
                    "../../Icons/Services/Marketing/SEO Link Building/Google My Business.svg",
                },
                {
                  sub_service_id: "idi00191",
                  sub_service_name: "On-Page SEO",
                  sub_service_image:
                    "../../Icons/Services/Marketing/SEO Link Building/On-Page SEO.svg",
                },
                {
                  sub_service_id: "idi00192",
                  sub_service_name: "Off-Page SEO",
                  sub_service_image:
                    "../../Icons/Services/Marketing/SEO Link Building/Off-Page SEO.svg",
                },
              ],
            },
          ],
        },
      ],

      subServices: "Accounting Services",
      subsubServices: "Company Formation",
      temp: "idiserv001",
      temp2: "idilevel001",
      selectArr: [],
      showModal: false,
    };
  }

  getSubService = (item, id) => {
    document.getElementById(this.state.temp2).style.color = "#000000";

    document.getElementById(id).style.color = "#14c514";

    var sub_serv_id, sub_serv_item;

    if (item == "Accounting Services") {
      sub_serv_item = "Company Formation";
      sub_serv_id = "idiserv001";
      //document.getElementById(sub_serv_id).style.color = "#14c514";
    } else if (item == "Legal") {
      sub_serv_item = "Registration";
      sub_serv_id = "idiserv0018";
      //document.getElementById(sub_serv_id).style.color = "#14c514";
    } else if (item == "Branding") {
      sub_serv_item = "Logo Design";
      sub_serv_id = "idiserv0019";
      //document.getElementById(sub_serv_id).style.color = "#14c514";
    } else if (item == "IT Services") {
      sub_serv_item = "IT Solutioning";
      sub_serv_id = "idiserv0044";
      //document.getElementById(sub_serv_id).style.color = "#14c514";
    } else {
      sub_serv_item = "Digital Marketing Services";
      sub_serv_id = "idiserv0034";
      //document.getElementById(sub_serv_id).style.color = "#14c514";
    }

    this.setState(
      {
        subServices: item,
        temp2: id,
        temp: sub_serv_id,
        subsubServices: sub_serv_item,
      },
      () => {
        document.getElementById(sub_serv_id).style.color = "#14c514";

        for (var i = 0; i < this.state.selectArr.length; i++) {
          if (document.getElementById(this.state.selectArr[i].idd)) {
            document.getElementById(this.state.selectArr[i].idd).style.border =
              "1px solid #14c514";
          }
        }
      }
    );
  };

  getSubSubService = (item, id) => {
    document.getElementById(this.state.temp).style.color = "#000000";

    document.getElementById(id).style.color = "#14c514";

    this.setState(
      {
        subsubServices: item,
        temp: id,
      },
      () => {
        for (var i = 0; i < this.state.selectArr.length; i++) {
          if (document.getElementById(this.state.selectArr[i].idd)) {
            document.getElementById(this.state.selectArr[i].idd).style.border =
              "1px solid #14c514";
          }
        }
      }
    );
  };

  selectService = (item, id) => {
    var flag = 0;
    var arr = [];

    arr = this.state.selectArr;

    for (var i = 0; i < this.state.selectArr.length; i++) {
      if (this.state.selectArr[i].name == item) {
        arr.splice(i, 1);
        flag = 1;
        if (document.getElementById(id)) {
          document.getElementById(id).style.border = "1px solid #9c9c9c";
        }
      }
    }
    if (flag == 0) {
      var list_item = {};

      list_item.name = item;
      list_item.idd = id;
      arr.push(list_item);
      document.getElementById(id).style.border = "2px solid #14c514";
    }

    this.setState(
      {
        selectArr: arr,
      },
      () => {}
    );
  };

  onGetQuote() {
    this.setState({
      showModal: true,
    });
  }

  clearSelected = () => {
    for (var i = 0; i < this.state.selectArr.length; i++) {
      if (document.getElementById(this.state.selectArr[i].idd)) {
        document.getElementById(this.state.selectArr[i].idd).style.border =
          "1px solid #9c9c9c";
      }
    }
    this.setState({
      selectArr: [],
    });
  };

  hideModal() {
    this.setState({
      showModal: false,
    });
    for (var i = 0; i < this.state.selectArr.length; i++) {
      if (document.getElementById(this.state.selectArr[i].idd)) {
        document.getElementById(this.state.selectArr[i].idd).style.border =
          "1px solid #9c9c9c";
      }
    }
    this.setState({
      selectArr: [],
    });
  }

  componentDidMount() {
    document.getElementById(this.state.temp2).style.color = "#14c514";
    document.getElementById(this.state.temp).style.color = "#14c514";
  }
  render() {
    return (
      <>
        {this.state.showModal ? (
          <CustomModal
            showModal={this.state.showModal}
            onClose={this.hideModal.bind(this)}
            selectedService={this.state.selectArr}
            title="Get Quote"
          ></CustomModal>
        ) : (
          <></>
        )}
        <div className="row" style={{ borderBottom: "1px solid #DCDCDC" }}>
          {this.state.custservice.map((each) => (
            <div className="col-md text-center custCol mt-3 lev1">
              <p
                onClick={() =>
                  this.getSubService(each.service_level1, each.service_level_id)
                }
                id={each.service_level_id}
              >
                <b>{each.service_level1}</b>
              </p>
            </div>
          ))}
        </div>
        <div className="row" style={{ borderBottom: "1px solid #DCDCDC" }}>
          {this.state.custservice.map((each) =>
            each.service_level1 == this.state.subServices ? (
              <>
                {each.service_list.map((eachsub) => (
                  <div className="col-md-2 text-center col-sm custCol mt-2 lev2">
                    <p
                      className="fontRes"
                      onClick={() =>
                        this.getSubSubService(
                          eachsub.service_name,
                          eachsub.service_id
                        )
                      }
                      id={eachsub.service_id}
                    >
                      {eachsub.service_name}
                    </p>
                  </div>
                ))}
              </>
            ) : null
          )}
        </div>

        <div
          className="row mt-1 pb-4 main-container"
          style={{ borderBottom: "1px solid #DCDCDC" }}
        >
          {this.state.custservice.map((each) =>
            each.service_level1 == this.state.subServices ? (
              <>
                {each.service_list.map((eachsub) =>
                  eachsub.service_name == this.state.subsubServices ? (
                    <>
                      {eachsub.subservice_list.map((eachsubsub) => (
                        
                        <div className="col-md-2 col-xs-2 mt-3 lev3">
                          <div
                            className="subserviceCard custCol"
                            onClick={() =>
                              this.selectService(
                                eachsubsub.sub_service_name,
                                eachsubsub.sub_service_id
                              )
                            }
                            id={eachsubsub.sub_service_id}
                          >
                            <img
                              src={eachsubsub.sub_service_image}
                              alt={eachsubsub.sub_service_name}
                            />
                            <p className="custCol-font">{eachsubsub.sub_service_name}</p>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : null
                )}
              </>
            ) : null
          )}
        </div>
        <div className="row mt-3">
          {this.state.selectArr.length > 0 ? (
            <div className="col-md-12 text-left">
              <p style={{ fontWeight: "500" }}>
                <b>Selected Services:</b>
              </p>
            </div>
          ) : (
            ""
          )}

          {!this.state.selectArr.length > 0 ? (
            <>
              {/* <div
                style={{
                  color: "#dc3545",
                  fontSize: ".875em",
                  marginTop: "0.25rem",
                }}
              > */}
              {/* Please select atleast one service from above. */}
              {/* </div> */}
            </>
          ) : (
            this.state.selectArr.map((each) => (
              <>
                <div
                  className="col-md-2 p-2 pl-4 pr-4 m-2 ml-3 mr-3"
                  id="serviceList"
                >
                  <span
                    style={{
                      backgroundColor: "#0C5DFF09",
                      fontSize: "12px",
                      padding: "2% 3%",
                    }}
                  >
                    {each.name}{" "}
                    <ClearIcon
                      className="ml-2"
                      onClick={() => this.selectService(each.name, each.idd)}
                      style={{ width: "0.6em" }}
                    />
                  </span>
                </div>
              </>
            ))
          )}
        </div>
        <div className="row mt-3">
          <div className="col-md-3 offset-md-9 btn_res">
            <Button
              disabled={!this.state.selectArr.length > 0}
              variant="primary"
              style={{ marginRight: "2%", borderRadius: "15px 0" }}
              onClick={this.clearSelected.bind(this)}
            >
              Clear All
            </Button>
            <Button
              disabled={!this.state.selectArr.length > 0}
              variant="primary"
              style={{ marginRight: "2%", borderRadius: "15px 0" }}
              onClick={this.onGetQuote.bind(this)}
            >
              Get Quote
            </Button>
          </div>
        </div>

        <p id="idiserv"></p>
      </>
    );
  }
}
