import React from "react"
import NavbarComponent from "./Navbar";

const LayoutComponent = ({ className, id, history, children }) => {
    return (
        <>
            <div className={"row " + className } id={id || ""} >
                <div
                    className="col-md-12 col-xs-12 col-sm-12 col-lg-12">
                    {/* <Header history={this.props.history} /> */}
                    <NavbarComponent history={history} />
                </div>
                <div
                    className="col-md-12 col-xs-12 col-sm-12 col-lg-12">
                    {children}
                </div>
            </div>
        </>
    )
}

export default LayoutComponent