import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { Form, FloatingLabel, Row, Col } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { send } from "emailjs-com";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { emailWelcome, emailSelf, emailSubscribe } from "./Email";

import Loader from "react-loader-spinner";
import CustomServices from "./CustomServices";

export default class CustomModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: false,
      mailValid: true,
      fullValid: true,
      phoneValid: true,
      messageValid: true,
      showMessage: true,
      message: "",
      phone: "",
      name: "",
      mail: "",
      // toSend: {
      //   from_name: "",
      //   from_email: "",
      //   from_mobile: "",
      //   from_message: "",
      //   subject: "",
      // },
      loader: false,
    };
  }

  // sendSelfMail(data) {
  //   const response = fetch("https://iemail.ideatoinfinite.com/selfmail", {
  //     method: "POST",
  //     headers: {
  //       "Content-type": "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   })
  //     .then((res) => res.json())
  //     .then(async (res) => {
  //       const resData = res;
  //     });
  // }

  sendClientMail(data) {
    const response = fetch(`${process.env.REACT_APP_WELCOME_EMAIL}`, {
          // const response = fetch(`http://localhost:3001/subscribes`, {

      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
    .then(this.handleError) 

      // .then((res) => res.json())

      .then(async (res) => {
        const resData = res;
        if (resData.status == "success") {
          toast.configure({
            pauseOnFocusLoss: false
          });
          toast.success(
            "Thank you for contacting us. We will get back to you soon.",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );

          this.setState({
            loader: false,
            nameValid: true,
            emailValid: true,
            name: "",
            email: "",
          });
          this.props.onClose();
        } else if (resData.status === "fail") {
          toast.error(
            "Sorry, we failed to submit your request. Please try again.",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
          this.setState({
            loader: false,
            nameValid: true,
            emailValid: true,
          });
        }
      });
  }
  handleError = (response) => {
    if (!response.ok) { 
      console.log(response)

       const res =fetch(`${process.env.REACT_APP_ERROR_LOGGER}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            error:`${response.statusText}`,
            errorCode:`${response.status}`,
            errorPage:`${document.URL}`,
            apiHit:`${response.url}`
          }),
        })
      //  throw Error(response.statusText);
    }
       return response.json();
  };
  handleSubmit = (event) => {
    var valid = true;
    event.preventDefault();
    event.stopPropagation();

    //First Name
    if (this.state.name != "") {
      this.setState({ fullValid: true });
    } else {
      this.setState({ fullValid: false });
      valid = false;
    }

    //Email
    if (
      /^[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[A-Za-z]+\.?[A-Za-z]{1,}$/.test(
        this.state.mail
      )
    ) {
      this.setState({ mailValid: true });
    } else {
      this.setState({ mailValid: false });
      valid = false;
    }

    //Phone
    if (this.state.phone.length > 10) {
      this.setState({ phoneValid: true });
    } else {
      this.setState({ phoneValid: false });
      valid = false;
    }

    //Message Field show/hide
    if (this.props.title === "Free Advice") {
      if (this.state.message.trim().length > 0) {
        this.setState({ messageValid: true });
      } else {
        valid = false;
        this.setState({ messageValid: false });
      }
    }

    if (
      !(
        this.props.title === "Choose Plan" || this.props.title === "Free Advice"
      )
    ) {
      if (this.props.selectedService != null) {
        var serviceSelected = "";
        serviceSelected =
          serviceSelected +
          this.props.selectedService.map((e) => {
            return e.name;
          });
      } else {
        serviceSelected = "NA";
      }
    }

    var subjectSelf = "";
    var messageSelf = "";
    var subjectClient = "";
    var messageClient = "";

    if (this.props.title === "Get Quote") {
      subjectSelf = "From Get Quote";
      messageSelf = "Services selected : " + serviceSelected;
      subjectClient = "Thanks for Choosing Idea to Infinite!";
      messageClient =
        "Hope you are doing great! We have received your request for quotation of our services. Please be assured, our team will reach out to you shortly.";
    }
    if (this.props.title === "Free Advice") {
      subjectSelf = "From Free Advice";
      messageSelf = this.state.message.trim();
      subjectClient = "Thanks for Giving Idea to Infinite a Try!";
      messageClient =
        "Thanks a ton, we have received your request to connect. Let's drive you towards infinite growth. Our team will reach out to you shortly.";
    }
    if (this.props.title === "Choose Plan") {
      subjectSelf = "From Choose Plan - IT Service page";
      messageSelf =
        "Plan selected : " +
        this.props.selectedService +
        ", Message : " +
        this.state.message.trim();
      subjectClient = "Thanks for Choosing Idea to Infinite!";
      messageClient =
        "Hope you are doing great! We are happy that you have shown interest in our website development plan. Please be assured, our team will reach out to you shortly.";
    }

    if (valid) {
      let data = {
        email: this.state.mail,
        name: this.state.name,
        mobile: this.state.phone,
        messageSelf: messageSelf,
        subjectSelf: subjectSelf,
        subjectClient: subjectClient,
        messageClient: messageClient,
      };

      this.setState(
        {
          loader: true,
        },
        () => {
          this.sendClientMail(data);
          // this.sendSelfMail(data);
        }
      );
    }
  };

  render() {
    return (
      <>
        <Modal
          show={this.props.showModal}
          onHide={this.props.onClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header
            closeButton
            style={{ backgroundColor: "#093276", color: "#fff" }}
          >
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              className="contactForm"
              noValidate
              validated={this.state.valid}
              onSubmit={(e) => {
                this.handleSubmit(e);
              }}
            >
              <Row className="mb-3 p-4 pt-0 pb-0">
                <Form.Group as={Col} md="12" controlId="FirstName">
                  <Form.Label
                    className="contactLabel2"
                    style={{ color: "gray" }}
                  >
                    Full Name
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Full Name"
                    style={{
                      color: "#000000",
                    }}
                    onChange={(typed) => {
                      this.setState({ name: typed.target.value });
                    }}
                    isInvalid={!this.state.fullValid}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide your Full Name
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3 p-4 pt-0 pb-0">
                <Form.Group as={Col} md="12" controlId="Email">
                  <Form.Label
                    className="contactLabel2"
                    style={{ color: "gray" }}
                  >
                    Email
                  </Form.Label>
                  <Form.Control
                    required
                    type="email"
                    placeholder="name@example.com"
                    style={{
                      color: "#000000",
                    }}
                    onChange={(typed) => {
                      this.setState({ mail: typed.target.value });
                    }}
                    isInvalid={!this.state.mailValid}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid Email.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="contactForm"
                  as={Col}
                  md="12"
                  controlId="Phone"
                >
                  <Form.Label
                    className="contactLabel2"
                    style={{ color: "gray" }}
                  >
                    Phone Number
                  </Form.Label>
                  <PhoneInput
                    className="col-md-12"
                    country={"in"}
                    inputStyle={{
                      color: "black",
                    }}
                    onChange={(typed) => {
                      this.setState({ phone: typed });
                    }}
                    isInvalid={!this.state.phoneValid}
                  />
                  {!this.state.phoneValid ? (
                    <div
                      style={{
                        color: "#dc3545",
                        fontSize: ".875em",
                        marginTop: "0.25rem",
                      }}
                    >
                      Please provide a valid Phone Number.
                    </div>
                  ) : (
                    ""
                  )}
                </Form.Group>
                {this.props.title === "Free Advice" ||
                this.props.title === "Choose Plan" ? (
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="Message"
                    className="contactForm mb-3"
                  >
                    <Form.Label
                      className="contactLabel2"
                      style={{ color: "gray" }}
                    >
                      Message
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Place your message here."
                      style={{ height: "80px", color: "black" }}
                      value={this.state.message}
                      onChange={(e) => {
                        this.setState({
                          message: e.target.value,
                        });
                      }}
                    />
                    {!this.state.messageValid ? (
                      <div
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                        }}
                      >
                        Please provide your Message.
                      </div>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                ) : (
                  ""
                )}
                <div className="col-md-12 mt-3" style={{ textAlign: "right" }}>
                  {this.state.loader ? (
                    <Loader
                      type="Puff"
                      color="#00BFFF"
                      height={30}
                      width={30}
                      className="loaderSpin"
                    />
                  ) : (
                    <Button
                      variant="primary"
                      type="submit"
                      className="modal-submit"
                      style={{ backgroundColor: "rgb(9, 50, 118)" }}
                    >
                      Submit
                    </Button>
                  )}
                  &nbsp;
                  <Button variant="secondary" onClick={this.props.onClose}>
                    Cancel
                  </Button>
                </div>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
