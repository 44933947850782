import React, { Component } from "react";
import Layout from "../BasicComp/Layout";
import Footer from "../BasicComp/Footer";
import Breadcrumb from "../BasicComp/Breadcrumb";
import bg from "../../img/contactus.jpeg";
import { Form, Button, FloatingLabel, Row, Col } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Contact2 from "../../img/Contacts.jpg";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { fontSize } from "@mui/system";
import { send } from "emailjs-com";
import { HashLink } from "react-router-hash-link";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MetaTags from "react-meta-tags";
import { ToastContainer, toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { emailWelcome, emailSelf, emailSubscribe } from "./Email";

export default class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: false,
      firstName: "",
      lastName: "",
      email: "",
      message: "",
      mailValid: true,
      firstValid: true,
      lastValid: true,
      phoneValid: true,
      phone: "",
      // toSend: {
      //   from_name: "",
      //   from_email: "",
      //   from_mobile: "",
      //   from_message: "",
      //   subject: "",
      // },
      loader: false,
    };
  }

  // sendSelfMail(data) {
  //   const response = fetch("https://iemail.ideatoinfinite.com/selfmail", {
  //     method: "POST",
  //     headers: {
  //       "Content-type": "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   })
  //     .then((res) => res.json())
  //     .then(async (res) => {
  //       const resData = res;
  //     });
  // }

  sendClientMail(data) {
    const response = fetch(`${process.env.REACT_APP_WELCOME_EMAIL}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(this.handleError)
      // .then((res) => res.json())
      .then(async (res) => {
        const resData = res;
        if (resData.status === "success") {
          toast.configure({
            pauseOnFocusLoss: false,
          });
          toast.success(
            "Thank you for contacting us. We will get back to you soon.",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
          this.setState({
            firstName: "",
            lastName: "",
            email: "",
            message: "",
            phone: "+91",
            mailValid: true,
            firstValid: true,
            lastValid: true,
            phoneValid: true,
            loader: false,
          });
        } else if (resData.status === "fail") {
          toast.error(
            "Sorry, we failed to submit your request. Please try again.",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
          this.setState({
            mailValid: true,
            firstValid: true,
            lastValid: true,
            phoneValid: true,
            loader: false,
          });
        }
      });
  }
  handleError = (response) => {
    if (!response.ok) {
      console.log(response);

      const res = fetch(`${process.env.REACT_APP_ERROR_LOGGER}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          error: `${response.statusText}`,
          errorCode: `${response.status}`,
          errorPage: `${document.URL}`,
          apiHit: `${response.url}`,
        }),
      });
      //  throw Error(response.statusText);
    }
    return response.json();
  };
  componentDidMount() {
    // if (window.location.protocol !== "https:") {
    //   window.location.replace(
    //     `https:${window.location.href.substring(
    //       window.location.protocol.length
    //     )}`
    //   );
    // }
    window.scrollTo(0, 0);
  }

  handleSubmit = (event) => {
    const form = event.currentTarget;
    var valid = true;
    this.setState({ valid: true });
  

    //First Name
    if (event.target[0].value.trim().length > 0) {
      this.setState({ firstValid: true });
    } else {
      this.setState({ firstValid: false });
      this.setState({ valid: false });
      valid = false;
    }

    //Last Name
    if (event.target[1].value.trim().length > 0) {
      this.setState({ lastValid: true });
    } else {
      this.setState({ lastValid: false });
      this.setState({ valid: false });
      valid = false;
    }

    //Email
    if (
      /^[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(event.target[2].value)
    ) {
      this.setState({ mailValid: true });
    } else {
      this.setState({ mailValid: false });
      this.setState({ valid: false });
      valid = false;
    }

    //Phone
    if (this.state.phone.length > 7) {
      this.setState({ phoneValid: true });
    } else {
      this.setState({ phoneValid: false });
      this.setState({ valid: false });
      valid = false;
    }

    if (valid) {
      let data = {
        name: event.target[0].value + " " + event.target[1].value,
        email: event.target[2].value,
        mobile: this.state.phone,
        messageSelf: event.target[4].value,
        subjectSelf: "New message from Contact Us form",
        subjectClient: "Thanks for Dropping by!",
        messageClient:
          "Thanks for connecting with us! We are glad to have you on our website. Our team will reach out to you shortly.",
      };
      //   this.setState({
      //     loader: true,
      //   });

      //   setTimeout(() => {
      //     emailWelcome(data);
      //   }, 1000);
      //   setTimeout(() => {
      //     emailSelf(data);
      //   }, 4000);

      //   setTimeout(() => {
      //     this.setState({
      //       firstName: "",
      //       lastName: "",
      //       email: "",
      //       message: "",
      //       phone: "+91",
      //       mailValid: true,
      //       firstValid: true,
      //       lastValid: true,
      //       phoneValid: true,
      //       toSend: {
      //         from_name: "",
      //         from_email: "",
      //         from_mobile: "",
      //         from_message: "",
      //         subject: "",
      //       },
      //       loader: false,
      //     });
      //     toast.success(
      //       "Thank you for connecting with us. We will get back to you soon.",
      //       {
      //         position: toast.POSITION.TOP_CENTER,
      //       }
      //     );
      //   }, 7000);

      this.setState(
        {
          loader: true,
        },
        () => {
          this.sendClientMail(data);
          // this.sendSelfMail(data);
        }
      );
    }

    event.preventDefault();
    event.stopPropagation();
  };

  render() {
    const contactBgStyle = {
      width: "100%",
      height: "auto",
      // backgroundImage: `linear-gradient(to top, #000000a6, #ffffff21),url(${Contact2})`,
      backgroundImage: `linear-gradient(to top, #000000a6, #ffffff21),url(Contact-webp/Contacts.webp)`,
      backgroundSize: "cover",
      backgroundPosition:"center center"
    };
    const items = [
      { text: "Home", url: "/" },
      { text: "Contact", url: "/contact" },
    ];
    return (
      <>
        <MetaTags>
          <title>Idea to Infinite -Contact</title>
          <meta
            name="description"
            content="Contact us today to learn what it’s like to work with our team of IT experts and how we can help your business succeed. Looking for a One-stop Expert IT service Provider? Stop your search here."
          />
          <meta
            property="og:title"
            content="Looking for a One-stop Expert IT Service Provider?"
          />
          <meta property="og:image" content="images/contact1.png" />
        </MetaTags>

        <div style={{ zIndex: "0" }}>
          <Layout history={this.props.history}></Layout>
        </div>
        <div style={{ zIndex: "0" }}>
          <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12"
           style={{
            // backgroundImage: `url(${bg})`,
            backgroundImage: `url(Contact-webp/contactus.webp)`,
            height: "585px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          >
            {/* <img
              src={bg}
              className="banner-image-contact"
              alt="Expert IT Service Provider"
            ></img> */}

            {/* <div className="row blogTextBg m-0"> */}
              <div className="bannerMod">
              <div className="banner-text contact_banner">
                <h1 className="responsive-headline">
                  Looking For A One-Stop Expert IT Service Provider?
                </h1>
                <h2 className="text-white" style={{ padding: "2% 0% 1%" }}>
                  We are here to simplify your journey towards Digital
                  Transformation. Get in touch with us to know more about all
                  our services for your enterprise IT needs.
                </h2>
              </div>
            </div>
          </div>
          <Breadcrumb items={items}/>
          <div className="row m-0 contactPara2">
            <div className="banner-text col-md-12 col-xs-12 col-sm-12 col-lg-12 pt-0">
              <h1 className="responsive-headline" style={{ color: "#000" }}>
                Contact Idea To Infinite To Start Your Transformation Journey
              </h1>
              <h2
                className="pt-0"
                style={{ color: "#000", fontWeight: "300", fontSize: "18px" }}
              >
                A superb team is at the heart of any successful transformation.
                Get expert advice on how to deliver workflows that give
                exceptional experiences in a more efficient manner. Please fill
                out the form below and an account executive will contact you
                shortly.
              </h2>
            </div>
          </div>

          <div className="row m-0" style={contactBgStyle}>
            <div
              className="col-md-5 col-xs-12 col-sm-12 readyText contact_tab"
              style={{ padding: "30% 5%" }}
            >
              <h1 className="" style={{ color: "#fff", fontSize: "30px" }}>
                Ready to get Started?
              </h1>
              <p
                className=""
                style={{ color: "#fff", fontSize: "16px", fontWeight: "500" }}
              >
                Call or email us at any time; we strive to respond to all
                inquiries within 24 hours on business days. We will be pleased
                to address any inquiries you may have.
              </p>
              <p
                className="footdesc readyText"
                style={{
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                <a
                  href={`tel:${process.env.REACT_APP_MobileNO}`}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {/* <p
                      style={{
                        color: "#fff",
                      }}
                    > */}
                  <PhoneIcon
                    style={{
                      color: "white",
                      fontSize: "22px",
                      marginRight: "3%",
                    }}
                  />
                  {"  "}
                  {process.env.REACT_APP_MobileNO}
                  {/* </p> */}
                </a>
              </p>
              <p
                className="footdesc readyText"
                style={{ fontSize: "16px", fontWeight: "500", width:"105%"}}
              >
                <a
                  href={`mailto:${process.env.REACT_APP_EMAIL}`}
                  style={{ textDecoration: "none", color: " white " }}
                >
                  {/* <p
                      style={{
                        color: "#fff",
                      }}
                    > */}
                  <MailOutlineIcon
                    style={{
                      color: "white",
                      fontSize: "22px",
                      marginRight: "3%",
                    }}
                  />
                  {"  "}
                  {process.env.REACT_APP_EMAIL}
                  {/* </p> */}
                </a>
              </p>
            </div>
            <div
              className="col-md-6 col-xs-12 col-sm-12 contactformbg"
              style={{ margin: "8% 0 4% 0" }}
            >
              <h1 className="" style={{ color: "#fff", fontSize: "26px" }}>
                We Are Just A Click Away.{" "}
              </h1>
              <p
                className="pt-2"
                style={{
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "100",
                  width: "100%",
                }}
              >
                Your email address will not be published. Required fields are
                marked *
              </p>
              <Form
                className="contactForm"
                noValidate
                validated={this.state.valid}
                onSubmit={(e) => {
                  this.handleSubmit(e);
                }}
              >
                <Row className="mb-3">
                  <Form.Group as={Col} md="12" controlId="FirstName">
                    <Form.Label className="contactLabel">
                      First Name *
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="First Name"
                      style={{
                        color: "#fff",
                      }}
                      value={this.state.firstName}
                      onChange={(e) => {
                        this.setState({
                          firstName: e.target.value,
                        });
                      }}
                    />
                    {!this.state.firstValid ? (
                      <div
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                        }}
                      >
                        Please provide a valid First Name.
                      </div>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="LastName">
                    <Form.Label className="contactLabel">
                      Last Name *
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Last Name"
                      style={{
                        color: "#fff",
                      }}
                      value={this.state.lastName}
                      onChange={(e) => {
                        this.setState({
                          lastName: e.target.value,
                        });
                      }}
                    />
                    {!this.state.lastValid ? (
                      <div
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                        }}
                      >
                        Please provide a valid Last Name.
                      </div>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} md="12" controlId="Email">
                    <Form.Label className="contactLabel">Email *</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      placeholder="name@example.com"
                      style={{
                        color: "#fff",
                      }}
                      value={this.state.email}
                      onChange={(e) => {
                        this.setState({
                          email: e.target.value,
                        });
                      }}
                    />
                    {!this.state.mailValid ? (
                      <div
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                        }}
                      >
                        Please provide a valid Email.
                      </div>
                    ) : (
                      ""
                    )}
                  </Form.Group>

                  <Form.Group
                    className="contactForm"
                    as={Col}
                    md="12"
                    controlId="Phone"
                  >
                    <Form.Label className="contactLabel">Phone Number*</Form.Label>
                    <PhoneInput
                      className="col-md-12"
                      country={"in"}
                      value={this.state.phone}
                      inputStyle={{
                        color: "white",
                      }}
                      onChange={(typed) => {
                        this.setState({ phone: typed });
                      }}
                      isInvalid={!this.state.phoneValid}
                    />
                    {!this.state.phoneValid ? (
                      <div
                        style={{
                          color: "#dc3545",
                          fontSize: ".875em",
                          marginTop: "0.25rem",
                        }}
                      >
                        Please provide a valid Phone Number.
                      </div>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </Row>
                <Form.Group
                  as={Col}
                  md="12"
                  controlId="Message"
                  className="contactForm mb-3"
                >
                  <Form.Label className="contactLabel">Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: "100px", color: "#fff" }}
                    value={this.state.message}
                    onChange={(e) => {
                      this.setState({
                        message: e.target.value,
                      });
                    }}
                  />
                </Form.Group>

                <div className="col-md-12" style={{ textAlign: "right" }}>
                  {this.state.loader ? (
                    <Loader
                      type="Puff"
                      color="#00BFFF"
                      height={30}
                      width={30}
                      className="loaderSpin"
                    />
                  ) : (
                    <Button
                      type="submit"
                      style={{
                        backgroundColor: "#fff",
                        color: "#000000",
                        borderRadius: "15px 0",
                      }}
                    >
                      Submit
                    </Button>
                  )}
                </div>
              </Form>
            </div>
            <div className="row">
              <div className="col-sm-12 text-center">
                <h1
                  className="p-0 mb-2"
                  style={{ color: "#fff", fontSize: "30px" }}
                ></h1>
                <p
                  className="text-center p-5 pt-2"
                  style={{
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "200",
                  }}
                ></p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12 footerBg"> */}
          <Footer />
        {/* </div> */}
      </>
    );
  }
}
