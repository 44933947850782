import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom"

import { HashLink } from "react-router-hash-link";
import "../../css/underline.css"
const NavbarComponent = ({ history }) => {
    const [color_Home, setcolor_Home] = useState("#b9b9b9");
    const [color_wedo, setcolor_wedo] = useState("#b9b9b9");
    const [color_services, setcolor_services] = useState("#b9b9b9");
    const [color_contact, setcolor_contact] = useState("#b9b9b9");
    const [colorChange, setcolorChange] = useState(false)
    const[menu,setMenu]=useState(false)
    


    useEffect(() => {
        window.addEventListener("scroll", changeNavbarColor);
        document.getElementById("nav-collapse").addEventListener("click",()=>setMenu(false))
        if (history?.location?.state?.id == "home") {
            // this.setState({
            //     color_Home: "#ffffff",
            //     color_wedo: "#b9b9b9",
            //     color_services: "#b9b9b9",
            //     color_contact: "#b9b9b9",
            // });
            setcolor_Home("#ffffff");
            setcolor_wedo("#b9b9b9");
            setcolor_services("#b9b9b9");
            setcolor_contact("#b9b9b9")
        } else if (history?.location?.state?.id == "about") {
            // this.setState({
            //     color_Home: "#b9b9b9",
            //     color_wedo: "#ffffff",
            //     color_services: "#b9b9b9",
            //     color_contact: "#b9b9b9",
            // });
            setcolor_Home("#b9b9b9");
            setcolor_wedo("#ffffff");
            setcolor_services("#b9b9b9");
            setcolor_contact("#b9b9b9")
        } else if (history?.location?.state?.id == "blog") {
            // this.setState({
            //     color_Home: "#b9b9b9",
            //     color_wedo: "#b9b9b9",
            //     color_services: "#ffffff",
            //     color_contact: "#b9b9b9",
            // });
            setcolor_Home("#b9b9b9");
            setcolor_wedo("#b9b9b9");
            setcolor_services("#ffffff");
            setcolor_contact("#b9b9b9")
        } else if (history?.location?.state?.id == "contact") {
            // this.setState({
            //     color_Home: "#b9b9b9",
            //     color_wedo: "#b9b9b9",
            //     color_services: "#b9b9b9",
            //     color_contact: "#ffffff",
            // });
            setcolor_Home("#b9b9b9");
            setcolor_wedo("#b9b9b9");
            setcolor_services("#b9b9b9");
            setcolor_contact("#ffffff")
        }
        else {
            // this.setState({
            //     color_Home: "#b9b9b9",
            //     color_wedo: "#b9b9b9",
            //     color_services: "#b9b9b9",
            //     color_contact: "#b9b9b9",
            // });
            setcolor_Home("#b9b9b9");
            setcolor_wedo("#b9b9b9");
            setcolor_services("#b9b9b9");
            setcolor_contact("#b9b9b9")
        }
    }, [])



    const changeNavbarColor = () => {
        if (window.scrollY >= 8) {
            // this.setState({
            //     colorChange: true,
            // });
            setcolorChange(true)
        } else {
            // this.setState({
            //     colorChange: false,
            // });
            setcolorChange(false)
        }
    };

    const onImageClick = () => {
        history.push({
            pathname: "/",
            state: { id: "home" },
        });
    };


    return (
        <div style={{ zIndex: "0" }}>
            <Navbar
                collapseOnSelect
                expand="lg"
                variant="dark"
                fixed="top"
                className="navBg"
                style={{
                    // backgroundColor: this.state.colorChange ? "#3E3E3E" : "transparent",
                    backgroundColor: colorChange ? "#fff" : "transparent",
                    boxShadow: colorChange ? "0 2px 4px 0 rgba(0,0,0,.2)" : "none"
                }}
            >
                <Container>
                    <Navbar.Brand>
                        <img
                            src={"/images/logo.png"}
                            alt="logo"
                            onClick={onImageClick}
                            className="navIcon"
                        />
                    </Navbar.Brand>
                    {/* <Navbar.Toggle aria-controls={"responsive-navbar-nav"} id="toggel" /> */}
                    <button id="toggel" type="button"
                        aria-label="Toggle navigation"
                        class="navbar-toggler collapsed"
                        fdprocessedid="ve8435"
                        // aria-controls="responsive-navbar-nav"
                        onClick={() => {
                            setMenu(!menu)
                        }}
                    >
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <Navbar.Collapse id="responsive-navbar-nav" className={menu?"navbar-collapse collapse show ":"navbar-collapse collapse"}>
                        <Nav className="me-auto"></Nav>
                        <Nav>
                            <NavLink
                                className="headerNavLink text-underline"
                                exact activeClassName="nav-active"
                                id="home"
                                to="/funhome"
                                style={{
                                    color: colorChange ? "black" : color_wedo,
                                }}
                            >
                                Home
                            </NavLink>

                            <NavLink
                                className="headerNavLink text-underline"
                                exact activeClassName="nav-active"
                                id="about"
                                to="/funabout"
                                style={{
                                    color: colorChange ? "black" : color_wedo,
                                }}
                            >
                                About Us
                            </NavLink>
                            <NavDropdown
                                className="headerNavLink text-underline test"
                                title={<span
                                    className="headerNavLinkService"
                                    style={{
                                        color: colorChange ? "black" : "rgb(185,185,185)",
                                    }}>Services</span>}
                                id="navbarScrollingDropdown"
                            >
                                <HashLink
                                    to="/funitservice"
                                >
                                    <NavDropdown.Item
                                        className="subService"
                                    >
                                        <NavLink
                                            to="/funitservice"
                                            exact activeClassName="nav-active"
                                            style={{
                                                color: colorChange ? "black" : "black",
                                            }}
                                        >
                                            IT Services
                                        </NavLink>
                                    </NavDropdown.Item>
                                </HashLink>
                                <HashLink
                                    to="/digital-transformation-services">
                                    <NavDropdown.Item
                                        className="subService"
                                    >
                                        <NavLink
                                            to="/fundigital"
                                            exact activeClassName="nav-active"
                                            style={{
                                                color: colorChange ? "black" : "black",
                                            }}
                                        >
                                            End-To-End Digital Transformation
                                        </NavLink>
                                    </NavDropdown.Item>
                                </HashLink>
                            </NavDropdown>
                            <NavLink
                                className="headerNavLink text-underline"
                                exact activeClassName="nav-active"
                                id="contact"
                                to="/funcontact"
                                style={{
                                    color: colorChange ? "black" : color_wedo,
                                }}
                            >
                                Contact Us
                            </NavLink>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}
export default NavbarComponent;