import React, { Component, useState, useEffect } from "react";
import LayoutComponent from "../../BasicComp/LayoutComponent";

import Layout from "../../BasicComp/Layout";
import Breadcrumb from "../../BasicComp/Breadcrumb";
import MetaTags from "react-meta-tags";
import Button from "react-bootstrap/Button";
import Loader from "react-loader-spinner";
import cta from "../../../img/cta_separator.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Footer from "../../BasicComp/Footer";
import FooterComponent from "../../BasicComp/FooterComponet";

// emailValid: true,
// name: "",
// email: "",
// loader: false,

const AboutPageFunctional = ({ history }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    const [nameValid, setNameValid] = useState(true)
    const [emailValid, setEmailValid] = useState(true)
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [loader, setLoader] = useState()


    const handleSubmit = (event) => {
        var valid = true;
        //Name
        if (name.length > 0) {
            // this.setState({
            //     nameValid: true,
            // });
            setNameValid(true)
        } else {
            setNameValid(false)

            valid = false;
        }

        //Email
        if (/^[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
            // this.setState({ emailValid: true });
            setEmailValid(true)
        } else {
            // this.setState({
            //     emailValid: false,
            // });
            setEmailValid(false)
            valid = false;
        }

        if (valid) {
            let data = {
                email: email,
                name: name,
                mobile: "",
                messageSelf: "",
                subjectSelf: "New message from Quick Contact",
                subjectClient: "Thanks for Your Recent Visit!",
                messageClient:
                    "Hope you are doing great! We are glad to have you connected. Our team will reach out to you shortly.",
            };
            // this.setState(
            //     {
            //         loader: true,
            //     },
            //     () => {
            //         sendClientMail(data);
            //     }
            // );
            setLoader(true)
            sendClientMail(data)
        }
        // event.preventDefault();
        // event.stopPropagation();
    };

    const sendClientMail = (data) => {

        console.log(data)
        const response = fetch(`${process.env.REACT_APP_WELCOME_EMAIL}`, {
            // const response = fetch(`http://localhost:3001/welcomes`, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify(data),
        })
            // .then((res) => res.json())
            .then(handleError)
            .then(async (res) => {
                const resData = res;
                if (resData.status == "success") {
                    toast.configure({
                        pauseOnFocusLoss: false,
                    });
                    toast.success(
                        "Thank you for contacting us. We will get back to you soon.",
                        {
                            position: toast.POSITION.TOP_CENTER,
                        }
                    );
                    // this.setState({
                    //     nameValid: true,
                    //     emailValid: true,
                    //     name: "",
                    //     email: "",
                    //     loader: false,
                    // });
                    setNameValid(true);
                    setEmailValid(true);
                    setName("");
                    setEmail("");
                    setLoader(false);
                } else if (resData.status === "fail") {
                    toast.error(
                        "Sorry, we failed to submit your request. Please try again.",
                        {
                            position: toast.POSITION.TOP_CENTER,
                        }
                    );
                    // this.setState({
                    //     nameValid: true,
                    //     emailValid: true,
                    //     loader: false,
                    // });
                    setNameValid(true);
                    setEmailValid(true);
                    setLoader(false)
                }
            });
    }
    const handleError = (response) => {
        if (!response.ok) {
            console.log(response);
            const res = fetch(`${process.env.REACT_APP_ERROR_LOGGER}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    error: `${response.statusText}`,
                    errorCode: `${response.status}`,
                    errorPage: `${document.URL}`,
                    apiHit: `${response.url}`,
                }),
            });
            //  throw Error(response.statusText);
        }
        return response.json();
    };

    var aboutCard1 = {
        padding: "0",
        // backgroundImage: `linear-gradient(to bottom, #000000a6, #ffffff21),url(${pp1})`,
        backgroundImage: `linear-gradient(to bottom, #000000a6, #ffffff21),url(About-webp/about1-1.webp)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        // linear-gradient(to bottom, #000000a6, #ffffff21), url(/static/media/about1-1.77454c95.jpg);
    };
    var aboutCard2 = {
        width: "100%",
        padding: "0",
        // backgroundImage: `linear-gradient(to bottom, #000000a6, #ffffff21),url(${pp2})`,
        backgroundImage: `linear-gradient(to bottom, #000000a6, #ffffff21),url(About-webp/about2-1.webp)`,

        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
    };
    var aboutCard3 = {
        width: "100%",
        padding: "0",
        // backgroundImage: `linear-gradient(to bottom, #000000a6, #ffffff21),url(${pp3})`,
        backgroundImage: `linear-gradient(to bottom, #000000a6, #ffffff21),url(About-webp/about3-1.webp)`,

        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
    };
    var ctaseparator = {
        width: "100%",
        padding: "0",
        backgroundImage: `url(${cta})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    };

    const items = [
        { text: "Home", url: "/" },
        { text: "About", url: "/funabout" },
    ];
    return (
        <>
            <MetaTags>
                <title>Idea to Infinite - About Us</title>
                <meta
                    name="description"
                    content="We take pride in being the fastest growing It Services company since 2010. Having passionate and accountable experts we Make Digital Possible!"
                />
                <meta
                    property="og:title"
                    content="Fastest Growing IT Services Company. We Make Digital Possible!"
                />
                <meta property="og:image" content="images/it_girl.png" />
            </MetaTags>

            <div style={{ zIndex: "0" }}>
                {/* <Layout history={history}></Layout> */}
                <LayoutComponent history={history} />
            </div>
            <div style={{ zIndex: "0" }}id="nav-collapse">
                <div
                    className="col-md-12 col-xs-12 col-sm-12 col-lg-12"
                    style={{
                        // backgroundImage: `url(images/AboutUS.png)`,
                        backgroundImage: `url(About-webp/AboutUS.webp)`,
                        height: "585px",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                    }}
                >
                    {/* <img
              src={"images/AboutUs.png"}
              className="banner-image"
              alt="Fastest Growing IT Services Company"
            ></img> */}

                    {/* <div className="row bannerTextBg aboutbannerText m-0">
             */}
                    <div className="bannerMod">
                        <div className="banner-text about-banner">
                            <h1 className="responsive-headline">
                                Fastest Growing IT Services Company <br />
                                We Make Digital Possible!
                            </h1>
                            <h2 className="text-white">
                                We take pride in being the only provider of end-to-end
                                services on a single platform. Having a team of passionate and
                                accountable experts simplifying IT across the globe.
                            </h2>
                        </div>
                    </div>
                </div>
                <Breadcrumb items={items} />
                <div className="row m-0">
                    <div className="col-md-12 text-center col-sm-12 pt-2">
                        <h3 className="sectionHeadText2">Company Overview</h3>
                        <h4>
                            <strong>Our idea to your infinity!</strong>
                        </h4>
                        <p
                            className="sectionParaText w-100 text-center"
                            style={{ padding: "1% 8% 2% 8%" }}
                        >
                            {/* <p> */}
                            {/* </p> */}
                            Leading businesses to the world of digital transformation. Idea
                            to Infinite is on the journey of simplifying the process of
                            digital transformation through our expertise in the world of
                            technology while providing end-to-end services on a single
                            platform. Idea to Infinite enables the transformation of your
                            business through versatile services from information technology
                            background and makes it even simpler by following agile
                            processes throughout the execution with end-to-end services.
                            Technology will always be dominant in the process of digital
                            transformation, so are the other milestones in the journey to
                            transform the business. We ideate and strategize the execution
                            of all the services required for a client which can rapidly
                            reduce the go to market time helping businesses to reach their
                            audience globally with forever association and infinite growth.
                        </p>
                    </div>
                </div>

                <div className="row m-0">
                    <div className="col-md-4 offset-md-4 col-sm-12">
                        <img
                            // src={"images/efew.png"}
                            src={"About-webp/efew.webp"}
                            className="w-100 p-3"
                            alt="Our mission"
                        ></img>
                    </div>
                </div>

                <div className="row m-0">
                    <div className="col-md-12 text-center col-sm-12 pt-5 pb-3">
                        <h3 className="sectionHeadText2">Our Mission</h3>
                    </div>
                </div>

                <div className="row m-0 about_sec">
                    <div className="col-md-6 offset-md-1 col-sm-12 img">
                        <div className="row m-0 mb-2" style={{ height: "100%" }}>
                            <div className="col-md-12 col-sm-12 mb-2 " style={aboutCard1}>
                                <div className="missionDiv">
                                    <h3 className="aboutMissionHeadText">
                                        Innovative Thinking
                                    </h3>
                                    <p
                                        className="missionParaText w-100"
                                        style={{ padding: "3% 6%" }}
                                    >
                                        We follow three basic thinking innovation processes – We
                                        take challenge | We create ideas | We perform action.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                        <div className="row m-0 mb-2">
                            <div className="col-md-12 col-sm-12 mb-3" style={aboutCard2}>
                                <div className="missionDiv">
                                    <h3 className="aboutMissionHeadText">
                                        Efficient Execution
                                    </h3>
                                    <p
                                        className="missionParaText w-100 missionMob_two"
                                        style={{ padding: "3% 6%" }}
                                    >
                                        Our experts execute efficiently – We communicate the key
                                        points | We develop tracking systems that ease problem
                                        solving | We set up formal reviews.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-12 col-sm-12" style={aboutCard3}>
                                <div className="missionDiv">
                                    <h3 className="aboutMissionHeadText">Infinite Growth</h3>
                                    <p
                                        className="missionParaText w-100 missionMob"
                                        style={{ padding: "3% 6%" }}
                                    >
                                        We help you to achieve infinite growth with our true
                                        business development success models and proven ROI.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row m-0">
                    <div className="col-md-12 text-center col-sm-12 pt-5 mobPad">
                        <h3 className="sectionHeadText2">Our Values</h3>
                        <p
                            className="sectionParaText1 w-100 text-center"
                            style={{ padding: "0% 15% 2% 15%" }}
                        >
                            Our Values guide our conduct at Idea to Infinite and our
                            business relationship with clients and partners.
                        </p>
                    </div>

                    <div className="col-md-4 text-center col-sm-12 p-5 pt-0 ">
                        <img
                            src={"Icons/Wow the customer (2).svg"}
                            className=""
                            style={{ width: "20%" }}
                            alt="customer"
                        ></img>
                        <h3 className="valuesHeadText">Wow the Customer</h3>
                        <p className="valuesText w-100" style={{ padding: "3% 6%" }}>
                            We create and guarantee customer delight – the principal purpose
                            of our business
                        </p>
                    </div>
                    <div className="col-md-4 text-center col-sm-12 p-5 pt-0">
                        <img
                            src={"Icons/Walk the talk (1).svg"}
                            className=""
                            style={{ width: "20%" }}
                            alt="walk the talk"
                        ></img>
                        <h3 className="valuesHeadText">Walk the Talk</h3>
                        <p className="valuesText w-100" style={{ padding: "3% 6%" }}>
                            We make promises that we can keep and conduct business with
                            integrity.
                        </p>
                    </div>
                    <div className="col-md-4 text-center col-sm-12 p-5 pt-0">
                        <img
                            src={"Icons/Will to win (2).svg"}
                            className=""
                            style={{ width: "20%" }}
                            alt="Will to win"
                        ></img>
                        <h3 className="valuesHeadText">Will To Win</h3>
                        <p className="valuesText w-100" style={{ padding: "3% 6%" }}>
                            We exhibit a strong will to win in the digital world and every
                            other aspect of our business.
                        </p>
                    </div>
                </div>

                <div className="row m-0 p-0 email_aboutus" style={ctaseparator}>
                    <div className="col-md-4 offset-md-1 col-sm-12 p-0 text-left email_text">
                        <h2
                            className="text-white ctaHead"
                            style={{ padding: "10% 2%", margin: "0" }}
                        >
                            Don't Wait! Reach Us Now!
                        </h2>
                    </div>
                    <div className="col-md-2 col-sm-12 text-center emailInputAboutUs">
                        <input
                            type="text"
                            placeholder="Enter Name Here"
                            style={{ width: "100%" }}
                            value={name}
                            className="form-control"
                            onChange={(e) => {
                                // this.setState({
                                //     name: e.target.value,
                                // });
                                setName(e.target.value)
                            }}
                        />
                        {!nameValid ? (
                            <div
                                style={{
                                    color: "#dc3545",
                                    fontSize: ".875em",
                                    marginTop: "0.25rem",
                                    textAlign: "left"
                                }}
                            >
                                Please provide a Name.
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="col-md-2 col-sm-12 text-center emailInputAboutUs">
                        <input
                            type="text"
                            placeholder="Enter Email Here"
                            style={{ width: "100%" }}
                            className="form-control"
                            value={email}
                            onChange={(e) => {
                                // this.setState({
                                //     email: e.target.value,
                                // });
                                setEmail(e.target.value)
                            }}
                        />
                        {!emailValid ? (
                            <div
                                style={{
                                    color: "#dc3545",
                                    fontSize: ".875em",
                                    marginTop: "0.25rem",
                                    textAlign: "left"
                                }}
                            >
                                Please provide a valid Email.
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="col-md-2 col-sm-12 flexclass  text-center emailInputAboutUs">
                        {loader ? (
                            <Loader
                                type="Puff"
                                color="#00BFFF"
                                height={30}
                                width={30}
                                className="loaderSpin"
                            />
                        ) : (
                            <Button
                                variant="outline-light"
                                className="contatcus_btn"
                                style={{
                                    borderRadius: "15px 0",
                                    padding: "4% 5%",
                                    fontSize: "14px",
                                    width: "50%",
                                }}
                                onClick={() => { handleSubmit() }}
                            >
                                Send
                            </Button>
                        )}
                    </div>
                </div>

                <div className="row m-0 p-0 showPosts">
                    <div className="col-xs-12 col-sm-12 sectionPadding pt-5 text-center">
                        <h3 className="sectionHeadText2 pt-4">Recent Posts</h3>
                        <p className="sectionParaText w-100 p-5 pb-2 pt-2 text-center">
                            Business and technology news from around the world. Exclusive
                            stories and <br />
                            expert analysis on digital transformation, technology, AI,
                            designing and more.
                        </p>
                    </div>
                </div>

                {/* <div className="container showPosts">     //Blog 
              <div className="row m-0">
                {this.state.posts.slice(1).map((post) => (
                  <BlogPreview post={post} />
                ))}
              </div>
            </div> */}

                {/* <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12 footerBg"> */}
                {/* <Footer history={history} /> */}
                <FooterComponent history={history}/>
                {/* </div> */}
            </div>
        </>
    )
}
export default AboutPageFunctional